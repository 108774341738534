import { fromJS } from 'immutable';
import queryString from 'query-string';

// Redux
import { asyncConnect } from 'redux-connect';
import {
  isBriefsLoaded,
  isFilterRoleSkillsLoaded,
  loadBriefs,
  loadFilterRoleSkills,
  loadOwnBriefs,
  loadOwnPitches,
} from 'redux/modules/projects2';
import { load as loadSuggestedRoles } from 'redux/modules/suggestedRoles2';
import { load as loadSuggestedSkills } from 'redux/modules/suggestedSkills2';
import { postBrief, clearPostedBrief } from 'redux/modules/projects';
import { getCitiesForCountry } from 'redux/modules/cities';
import { loadPartners } from 'redux/modules/partners';
import {
  setSelectedRoleCategory,
  clearSelectedRoleCategory,
} from 'redux/modules/selectedRoleCategory';

import loadable from '@loadable/component';

const ProjectsBase = loadable(() => import('./ProjectsBase'));

const Projects = asyncConnect(
  [
    {
      promise: ({
        store: { dispatch, getState },
        match: {
          params: { role, city, country },
        },
        location,
      }) => {
        const paid = location.pathname.includes('/jobs');
        const promises = [];

        if (!isFilterRoleSkillsLoaded(getState(), paid)) {
          promises.push(dispatch(loadFilterRoleSkills(paid)));
        }

        // Parse roles from URL slug/param
        const filteredRoles = typeof role !== 'undefined' ? [role] : [];
        const parsedRoles = queryString.parse(location.search).roles;

        if (typeof parsedRoles === 'string') {
          filteredRoles.push(parsedRoles);
        } else if (typeof parsedRoles === 'object') {
          parsedRoles.forEach((r) => filteredRoles.push(r));
        }

        // Parse experienceLevels from query string
        const filteredExperienceLevels = [];
        const parsedExperienceLevels = queryString.parse(
          location.search
        ).experienceLevel;

        if (typeof parsedExperienceLevels === 'string') {
          // 1 experience level filtered - represented as string
          filteredExperienceLevels.push(parsedExperienceLevels);
        } else if (typeof parsedExperienceLevels === 'object') {
          // multiple experienceLevels filtered - represented as string[]
          parsedExperienceLevels.forEach((l) =>
            filteredExperienceLevels.push(l)
          );
        }

        const experienceLevels = [
          { type: 'Amateur', isChecked: false },
          { type: 'Beginner', isChecked: false },
          { type: 'Junior', isChecked: false },
          { type: 'Mid-level', isChecked: false },
          { type: 'Senior', isChecked: false },
          { type: 'Expert', isChecked: false },
        ];

        // Update `isChecked` for the experience levels found in the query string
        filteredExperienceLevels.forEach((l) => {
          const parsedExperienceLevel = experienceLevels.find(
            (el) => el.type === l
          );

          if (typeof parsedExperienceLevel !== 'undefined') {
            parsedExperienceLevel.isChecked = true;
          }
        });

        // Parse hiringCapacities from query string
        const filteredHiringCapacities = [];
        const parsedHiringCapacities = queryString.parse(
          location.search
        ).hiringCapacity;

        if (typeof parsedHiringCapacities === 'string') {
          // 1 hiring capacity filtered - represented as string
          filteredHiringCapacities.push(parsedHiringCapacities);
        } else if (typeof parsedHiringCapacities === 'object') {
          // multiple hiring capacities filtered - represented as string[]
          parsedHiringCapacities.forEach((h) =>
            filteredHiringCapacities.push(h)
          );
        }

        const hiringCapacities = [
          { type: 'personal', isChecked: false },
          { type: 'commercial', isChecked: false },
        ];

        // Update `isChecked` for the hiring capacities found in the query string
        filteredHiringCapacities.forEach((h) => {
          const parsedHiringCapacity = hiringCapacities.find(
            (hc) => hc.type === h
          );

          if (typeof parsedHiringCapacity !== 'undefined') {
            parsedHiringCapacity.isChecked = true;
          }
        });

        const jobLocationText = city ? `${city}, ${country}` : country;

        // Parse filters from query string
        const { status, remote, internal, searchTerm } = queryString.parse(
          location.search
        );

        // Load Briefs
        if (
          !isBriefsLoaded(
            getState(),
            paid,
            filteredRoles,
            experienceLevels,
            hiringCapacities,
            status,
            remote,
            internal,
            jobLocationText,
            searchTerm
          )
        ) {
          const action = loadBriefs(
            1,
            paid,
            filteredRoles,
            experienceLevels,
            hiringCapacities,
            status,
            remote,
            internal,
            jobLocationText,
            searchTerm
          );
          promises.push(dispatch(action));
        }

        return Promise.all(promises);
      },
    },
  ],
  (state) => ({
    user: state.auth.user,
    projects: fromJS(state.projects2),
    suggestedRoles: fromJS(state.suggestedRoles2),
    suggestedSkills: fromJS(state.suggestedSkills2),
    briefsEntities: fromJS(state.entities.briefs),
    roleEntities: fromJS(state.entities.roles),
    skillEntities: fromJS(state.entities.skills),
    userEntities: fromJS(state.entities.users),
    posted: state.projects.posted,
    posting: state.projects.posting,
    cities: state.cities,
    loadingPartners: state.partners.loadingPartners,
    loadedPartners: state.partners.loadedPartners,
    partners: state.partners.partners,
  }),
  {
    loadBriefs,
    loadFilterRoleSkills,
    loadPartners,
    loadOwnBriefs,
    loadOwnPitches,
    loadSuggestedRoles,
    loadSuggestedSkills,
    clearPostedBrief,
    postBrief,
    getCitiesForCountry,
    setSelectedRoleCategory,
    clearSelectedRoleCategory,
  }
)(ProjectsBase);

export default Projects;
