import React from 'react';
import PropTypes from 'prop-types';

const MarketingSkills = ({ type }) => {
  if (type === 'freelancer') {
    return (
      <>
        <li>
          <a href="/jobs/content-creators">Content Creation Jobs</a>
        </li>
        <li>
          <a href="/jobs/digital-marketers">Digital Marketing Jobs</a>
        </li>
        <li>
          <a href="/jobs/social-media-freelancers">
            Social Media Freelance Jobs
          </a>
        </li>
        <li>
          <a href="/jobs/pr-freelancers">PR Freelance Jobs</a>
        </li>
        <li>
          <a href="/jobs/social-media-managers">Social Media Manager Jobs</a>
        </li>
        <li>
          <a href="/jobs/pitch-deck-designers">Pitch Deck Designer Jobs</a>
        </li>
        <li>
          <a href="/jobs/presentation-designers">Presentation Designer Jobs</a>
        </li>
      </>
    );
  }

  return (
    <>
      <li>
        <a href="/find/content-creators">Content Creators</a>
      </li>
      <li>
        <a href="/find/digital-marketers">Digital Marketers</a>
      </li>
      <li>
        <a href="/find/social-media-freelancers">Social Media Freelancers</a>
      </li>
      <li>
        <a href="/find/pr-freelancers">PR Freelancers</a>
      </li>
      <li>
        <a href="/find/social-media-managers">Social Media Managers</a>
      </li>
      <li>
        <a href="/find/pitch-deck-designers">Pitch Deck Designers</a>
      </li>
      <li>
        <a href="/find/seo-experts">SEO Experts</a>
      </li>
      <li>
        <a href="/find/copywriters">Copywriters</a>
      </li>
      <li>
        <a href="/find/ppc-managers">PPC Managers</a>
      </li>
    </>
  );
};

MarketingSkills.propTypes = {
  type: PropTypes.string.isRequired,
};

export default MarketingSkills;
