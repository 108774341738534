import React from 'react';
import styles from '../Footer.module.scss';

const Comparison = () => {
  return (
    <ul className={styles.footerList}>
      <li className={styles.subTitle}>
        <a href="/freelancers">
          <strong>Comparison</strong>
        </a>
      </li>
      <li>
        <a href="/blog/the-10-best-alternatives-to-upwork/">Twine vs Upwork</a>
      </li>
      <li>
        <a href="/blog/the-10-best-alternatives-to-fiverr/">Twine vs Fiverr</a>
      </li>
      <li>
        <a href="/blog/the-10-best-alternatives-to-99designs/">
          Twine vs 99Designs
        </a>
      </li>
      <li>
        <a href="/blog/the-10-best-alternatives-to-toptal/">Twine vs Toptal</a>
      </li>
      <li>
        <a href="/blog/the-10-best-alternatives-to-linkedin/">
          Twine vs LinkedIn
        </a>
      </li>
    </ul>
  );
};

export default Comparison;
