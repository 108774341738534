import { toPlural, toSeoUrl } from './HelperFunctions';

export const skillRoleObject = {
  '.NET': 'Developers',
  'Ableton Live': 'Music Producers',
  Abridged: 'Copywriters',
  Acapella: 'Vocalists',
  'ACID Pro': 'Music Producers',
  'Active Directory': 'Developers',
  Ada: 'Developers',
  Adalo: 'Developers',
  ADK: 'Designers',
  'Adobe After Effects': 'Motion Graphic Designers',
  'Adobe Animate': '2D Animators',
  'Adobe Audition': 'Sound Editors',
  'Adobe Character Animator': '2D Animators',
  'Adobe ColdFusion': 'Web Developers',
  'Adobe Dimenson': 'Designers',
  'Adobe Dreamweaver': 'Web Designers',
  'Adobe Firefly': 'Graphic Designers',
  'Adobe FreeHand': 'Illustrators',
  'Adobe Illustrator': 'Designers',
  'Adobe InDesign': 'Graphic Designers',
  'Adobe Lightroom': 'Photographers',
  'Adobe Photoshop': 'Graphic Designers',
  'Adobe Premiere Elements': 'Video Editors',
  'Adobe Premiere Pro': 'Video Editors',
  'Adobe Spark': 'Digital Marketers',
  'Adobe XD': 'UI Designers',
  'Affinity Designer': 'Illustrators',
  'AI Chatbot': 'Developers',
  Airtable: 'Developers',
  Akeneo: 'Developers',
  ALGOL: 'Developers',
  'Amazon Web Services': 'Cloud Developers',
  Android: 'App Developers',
  AngularJS: 'Web Developers',
  Anime: 'Animators',
  'Apache Groovy': 'Developers',
  API: 'Developers',
  AppDrag: 'Developers',
  AppGyver: 'App Developers',
  AppSheet: 'App Developers',
  'ArtRage 5': 'Artists',
  'ASP.NET': 'Developers',
  Assembly: 'Developers',
  Augmania: 'Digital Marketers',
  Aurelia: 'Front-End Developers',
  'Autodesk Flame': 'Visual Effects Artists',
  'Autodesk Maya': '3D Animators',
  'Autodesk SketchBook': 'Illustrators',
  AWK: 'Developers',
  AWS: 'Cloud Developers',
  Axiom: 'Developers',
  Axure: 'Designers',
  Azure: 'Cloud Developers',
  'Backing Vocal': 'Musicians',
  Banner: 'Graphic Designers',
  BCPL: 'Developers',
  'Betty Blocks': 'App Developers',
  Bildr: 'Developers',
  Bitcoin: 'Blockchain Developers',
  Blender: 'Animators',
  'Blue Prism': 'Developers',
  Blueprints: 'Artists',
  Bolt: 'Developers',
  BoonEx: 'Developers',
  Bootstrap: 'Web Developers',
  'Bravo Studio': 'Developers',
  Bubble: 'App Developers',
  Buildbox: 'Game Developers',
  Builder: 'App Developers',
  BuilderX: 'Developers',
  C: 'Developers',
  'C#': 'Developers',
  'C++': 'Developers',
  CakePHP: 'Web Developers',
  'Cakewalk Sonar': 'Music Producers',
  Camtasia: 'Video Editors',
  Canva: 'Graphic Designers',
  CapCut: 'Video Editors',
  Carrd: 'Developers',
  Cartoon: '2D Animators',
  Caspio: 'Developers',
  Cassandra: 'Database Developers',
  'CelAction Animatic': 'Animators',
  CelAction2D: 'Animators',
  Chapel: 'Developers',
  ChatGPT: 'AI Prompt Engineer',
  'Chrome Extension': 'Developers',
  Citrix: 'Data Visualizers',
  Clappia: 'App Developers',
  Claude: 'AI Prompt Engineer',
  Claymation: 'Animators',
  Clojure: 'Developers',
  CodeIgniter: 'Web Developers',
  Cojent: 'Developers',
  'Corel Draw': 'Graphic Designers',
  CorelDraw: 'Graphic Designers',
  'Corporate Video': 'Content Producers',
  CRM: 'Digital Marketers',
  Crystal: 'Developers',
  CSS: 'Web Developers',
  'Cursor Composer': 'Developers',
  CVAT: 'Data Annotator',
  'Cyberlink PowerDirector': 'Video Editors',
  D: 'Developers',
  Dafny: 'Developers',
  Dart: 'Developers',
  'DaVinci Resolve Studio': 'Video Editors',
  DB2: 'Database Developers',
  DevExpress: 'Developers',
  Django: 'Web Developers',
  Docker: 'DevOps Developers',
  Draftbit: 'Developers',
  DreamFactory: 'Developers',
  DronaHQ: 'Developers',
  Drupal: 'CMS Developers',
  DynamoDB: 'Database Developers',
  Edius: 'Video Editors',
  Eiffel: 'Developers',
  Elasticsearch: 'Developers',
  Elementor: 'Web Developers',
  Elixir: 'Developers',
  Elm: 'Developers',
  'Emacs Lisp': 'Developers',
  'Email Marketing': 'Digital Marketers',
  'Ember.js': 'Web Developers',
  Erlang: 'Developers',
  Ethereum: 'Blockchain Developers',
  ETL: 'Database Developers',
  'Explainer Video': 'Content Producers',
  Express: 'Web Developers',
  'F#': 'Developers',
  'Facebook Ads': 'Digital Marketers',
  'Facebook API': 'Developers',
  Facebook: 'Social Media Freelancers',
  Factor: 'Developers',
  'Fashion Illustration': 'Artists',
  Figma: 'Designers',
  Filmora: 'Video Editors',
  'Final Cut Pro X': 'Video Editors',
  Finale: 'Music Composers',
  Fivetran: 'Database Developers',
  'FL Studio': 'Music Producers',
  Flutter: 'Developers',
  FlutterFlow: 'Developers',
  Forth: 'Developers',
  Fortran: 'Developers',
  Framer: 'Developers',
  FreeBasic: 'Developers',
  GarageBand: 'Music Composers',
  GIF: 'Animators',
  GIMP: 'Graphic Designers',
  Go: 'Developers',
  'Google Ads': 'Digital Marketers',
  'Google API': 'Developers',
  'Google Cloud': 'Cloud Developers',
  'Google Maps': 'Developers',
  'Gouache Illustration': 'Artists',
  GPT: 'AI Engineers',
  Grails: 'Developers',
  Grapedrop: 'Web Designers',
  GraphQL: 'Developers',
  Groovy: 'Developers',
  Hack: 'Developers',
  Hadoop: 'Developers',
  Haskell: 'Developers',
  HTML5: 'Web Developers',
  HubSpot: 'Digital Marketers',
  Icon: 'Graphic Designers',
  Idris: 'Developers',
  iMovie: 'Video Editors',
  inkscape: 'Illustrators',
  'Instagram API': 'Developers',
  Instagram: 'Social Media Freelancers',
  Invision: 'Graphic Designers',
  Io: 'Developers',
  ionic: 'Developers',
  iOS: 'App Developers',
  J: 'Developers',
  'J#': 'Developers',
  Java: 'Developers',
  JavaScript: 'Web Developers',
  Jimdo: 'Web Developers',
  Jira: 'Developers',
  jQuery: 'Web Developers',
  JScript: 'Developers',
  Julia: 'Developers',
  Keras: 'AI Engineers',
  Kind: 'Developers',
  Kling: 'AI Prompt Engineer',
  Kotlin: 'Developers',
  Kubernetes: 'DevOps Developers',
  Laravel: 'Web Developers',
  'Large Language Model': 'AI Engineers',
  'Lead Nurturing': 'Digital Marketers',
  LinkedIn: 'Social Media Freelancers',
  Lisp: 'Developers',
  LiveScript: 'Developers',
  Llama: 'AI Engineers',
  LMS: 'Developers',
  Lobster: 'Developers',
  'Logic Pro': 'Music Producers',
  Lovable: 'Developers',
  Lua: 'Developers',
  Magento: 'Web Developers',
  Mailchimp: 'Digital Marketers',
  MariaDB: 'Database Developers',
  'MAXON Cinema 4D': 'Animators',
  'Mean Stack': 'Developers',
  Miaoda: 'Developers',
  'Microsoft Excel': 'Developers',
  'Microsoft Powerpoint': 'Graphic Designers',
  'Microsoft SQL': 'Database Developers',
  Midjourney: 'Artists',
  MikuMikuDance: '3D Animators',
  Mixamo: '3D Animators',
  ML: 'Developers',
  'Modula-2': 'Developers',
  Mojo: 'Developers',
  Monday: 'Developers',
  MongoDB: 'Database Developers',
  'Music Video': 'Video Editors',
  MySQL: 'Database Developers',
  Neo4j: 'Database Developers',
  Nim: 'Developers',
  'Node.js': 'Web Developers',
  Noloco: 'Developers',
  'Objective-C': 'Developers',
  OpenCL: 'Developers',
  'OpenEdge ABL': 'Developers',
  OpenStack: 'Developers',
  Oracle: 'Database Developers',
  Outfunnel: 'Digital Marketers',
  PayPal: 'Developers',
  'Pen and Ink Illustration': 'Freelance Digital Illustrators',
  'Pencil Illustration': 'Freelance Digital Illustrators',
  Pencil2D: '2D Animators',
  Perplexity: 'AI Prompt Engineer',
  PHP: 'Web Developers',
  phpBB: 'Web Developers',
  Pinterest: 'Social Media Freelancers',
  'Pitch Deck': 'Graphic Designers',
  'PL SQL': 'Database Developers',
  'PL/I': 'Developers',
  Pony: 'Developers',
  Portrait: 'Photographers',
  POS: 'Developers',
  PostgreSQL: 'Database Developers',
  PowerShell: 'Developers',
  'PreSonus Studio One': 'Music Producers',
  'Pro Tools': 'Music Producers',
  Procreate: 'Designers',
  'Product Video': 'Content Creators',
  Prolog: 'Developers',
  'Promo Video': 'Content Creators',
  'Propellerhead Reason': 'Music Producers',
  PureScript: 'Developers',
  Python: 'Developers',
  PyTorch: 'AI Engineers',
  'Q#': 'Developers',
  Qt: 'Developers',
  Quora: 'Social Media Freelancers',
  Qwen: 'AI Prompt Engineer',
  R: 'Developers',
  Racket: 'Developers',
  'Raspberry Pi': 'Developers',
  'React JS': 'Web Developers',
  'React Native': 'Developers',
  REAKTOR: 'Music Producers',
  'Reallusion Cartoon': '2D Animators',
  'Reallusion Character Creator': '3D Animators',
  'Reallusion iClone': '3D Animators',
  Reason: 'Developers',
  Red: 'Developers',
  Reddit: 'Social Media Freelancers',
  Redis: 'Database Developers',
  'Replit Agent': 'Developers',
  Retool: 'Developers',
  Rexx: 'Developers',
  Ring: 'Developers',
  'Rosebud AI': 'Developers',
  Rotoscope: 'Animators',
  'Ruby on Rails': 'Web Developers',
  Ruby: 'Web Developers',
  'Runway ML': 'AI Prompt Engineer',
  Rust: 'Developers',
  SaaS: 'Developers',
  'Sails.js': 'Web Developers',
  Salesforce: 'Developers',
  Scala: 'Developers',
  Selenium: 'QA Engineers',
  'SGO Mistika': 'Video Editors',
  Shopify: 'Developers',
  'Short Film': 'Content Producers',
  Sibelius: 'Music Composers',
  Sketch: 'Designers',
  'Slack Workflow': 'Developers',
  'Slide Deck': 'Graphic Designers',
  Smalltalk: 'Developers',
  Smarty: 'Developers',
  Snapchat: 'Social Media Freelancers',
  Snowflake: 'Developers',
  'Socket.IO': 'Developers',
  Solidity: 'Blockchain Developers',
  Spring: 'Developers',
  SQL: 'Database Developers',
  SQLite: 'Database Developers',
  Squarespace: 'Web Developers',
  'Stable Diffusion': 'Developers',
  'Steinberg Cubase': 'Music Producers',
  'Steinberg Nuendo': 'Sound Editors',
  'Steinberg Wavelab': 'Mastering Engineers',
  'Stop Action': 'Photographers',
  'Stop Motion': 'Photographers',
  Stripe: 'Developers',
  Subframe: 'Developers',
  Swift: 'Developers',
  Symfony: 'Web Developers',
  Tailwind: 'Front-End Developers',
  Tcl: 'Developers',
  TensorFlow: 'AI Engineers',
  Tiktok: 'Social Media Freelancers',
  Titanium: 'Developers',
  'Toon Boom Harmony': '2D Animators',
  'Toon Boom Producer': 'Creative Producers',
  'Toon Boom Storyboard Pro': 'Illustrators',
  Traktor: 'DJs',
  Tumblr: 'Digital Marketers',
  Twilio: 'Developers',
  Twinmotion: '3D Designers',
  Twitch: 'Content Creators',
  'Twitter Ads': 'Digital Marketers',
  'Twitter API': 'Developers',
  Twitter: 'Social Media Freelancers',
  TypeScript: 'Web Developers',
  'UI Path': 'Developers',
  'Unity 3D': 'Developers',
  Unity: 'Game Developers',
  'Unreal Engine 3': 'Game Developers',
  'Unreal Engine 4': 'Game Developers',
  v0: 'Developers',
  'V7 Labs': 'Data Annotator',
  Vala: 'Developers',
  Veed: 'Video Editors',
  'Vegas Pro': 'Video Editors',
  VHDL: 'Developers',
  'Video Advert': 'Content Creators',
  VMware: 'DevOps Developers',
  Vocaloid: 'Music Producers',
  Voiceflow: 'Developers',
  'Vue.js': 'Web Developers',
  web2py: 'Developers',
  Webcrumbs: 'Developers',
  Webflow: 'Web Developers',
  WebKit: 'Developers',
  WeChat: 'Social Media Managers',
  Weebly: 'Developers',
  WeWeb: 'Developers',
  'Whiteboard Video': 'Animators',
  WiX: 'Web Developers',
  WordPress: 'Web Developers',
  Xamarin: 'Developers',
  XML: 'Developers',
  'Yelp API': 'Developers',
  'YouTube API': 'Developers',
  YouTube: 'Content Creators',
  Zapier: 'Developers',
  ZBrush: 'Designers',
  Zend: 'Web Developers',
  Zig: 'Developers',
};

const roleCategories = {
  '1st-assistant-directors': 'Video & Animation',
  '2d-animators': 'Video & Animation',
  '2nd-assistant-directors': 'Video & Animation',
  '3d-animators': 'Video & Animation',
  '3d-designers': 'Graphic Design',
  '3d-visualizers': 'Graphic Design',
  'abm-marketers': 'Business & Startups',
  actors: 'Video & Animation',
  actresses: 'Video & Animation',
  'affiliate-marketers': 'Business & Startups',
  'ai-developers': 'AI',
  'ai-engineers': 'App & Web Development',
  'ai-ethics-consultants': 'App & Web Development',
  'ai-prompt-engineers': 'AI',
  'ai-strategy-consultants': 'App & Web Development',
  'album-art-designers': 'Graphic Design',
  'animation-directors': 'Video & Animation',
  animators: 'Video & Animation',
  'app-developers': 'App & Web Development',
  architects: 'Graphic Design',
  'architectural-illustrators': 'Graphic Design',
  arrangers: 'Music',
  'art-directors': 'Video & Animation',
  artists: 'Graphic Design',
  artworkers: 'Graphic Design',
  'assistant-editors': 'Video & Animation',
  'assistant-producers': 'Video & Animation',
  'audio-engineers': 'Music',
  'audio-mixers': 'Music',
  'audio-transcribers': 'Business & Startups',
  'back-end-developers': 'App & Web Development',
  'background-designers': 'Video & Animation',
  'band-members': 'Music',
  bands: 'Music',
  banjoists: 'Music',
  'banner-ad-designers': 'Graphic Design',
  'bass-drum-players': 'Music',
  bassists: 'Music',
  beatboxers: 'Music',
  beatmakers: 'Music',
  'blockchain-developers': 'App & Web Development',
  'book-cover-designers': 'Graphic Design',
  'book-illustrators': 'Graphic Design',
  'brand-designers': 'Graphic Design',
  'brand-strategists': 'Business & Startups',
  'camera-operators': 'Video & Animation',
  'caricature-artists': 'Graphic Design',
  'casting-directors': 'Video & Animation',
  cellists: 'Music',
  'chamber-orchestras': 'Music',
  'character-designers': 'Video & Animation',
  'character-riggers': 'Video & Animation',
  'childrens-book-illustrators': 'Graphic Design',
  choreographers: 'Video & Animation',
  cinematographers: 'Video & Animation',
  'cloud-developers': 'App & Web Development',
  'cms-developers': 'App & Web Development',
  'co-writers': 'Video & Animation',
  colourists: 'Video & Animation',
  'comic-illustrators': 'Graphic Design',
  'community-managers': 'Digital Marketing',
  competitions: 'Graphic Design',
  composers: 'Music',
  'concept-artists': 'Digital Marketing',
  'concept-visualizers': 'Graphic Design',
  conductors: 'Music',
  'content-creators': 'Video & Animation',
  'content-designers': 'Digital Marketing',
  'content-producers': 'Video & Animation',
  'content-strategists': 'Digital Marketing',
  'conversion-rate-optimization-experts': 'Business & Startups',
  copywriters: 'Digital Marketing',
  'costume-designers': 'Video & Animation',
  'costume-fitters': 'Video & Animation',
  'creative-directors': 'Video & Animation',
  'creative-producers': 'Video & Animation',
  dancers: 'Video & Animation',
  'data-annotators': 'AI',
  'data-collectors': 'AI',
  'data-scientists': 'App & Web Development',
  'data-visualizers': 'Graphic Design',
  'database-developers': 'App & Web Development',
  designers: 'Graphic Design',
  developers: 'App & Web Development',
  'devops-developers': 'App & Web Development',
  'digital-marketers': 'Digital Marketing',
  'digital-producers': 'Video & Animation',
  'director-of-photographys': 'Video & Animation',
  djs: 'Music',
  'documentary-filmmakers': 'Video & Animation',
  'double-bassists': 'Music',
  drummers: 'Music',
  'ecommerce-developers': 'App & Web Development',
  'event-visualizers': 'Graphic Design',
  'executive-producers': 'Video & Animation',
  extras: 'Video & Animation',
  'film-composers': 'Video & Animation',
  'film-directors': 'Video & Animation',
  'film-fixers': 'Video & Animation',
  'film-producers': 'Video & Animation',
  filmmakers: 'Video & Animation',
  flutists: 'Music',
  'flyer-designers': 'Graphic Design',
  'freelance-digital-illustrators': 'Graphic Design',
  'front-end-developers': 'App & Web Development',
  'full-stack-developers': 'App & Web Development',
  'game-composers': 'Music',
  'game-designers': 'App & Web Development',
  'game-developers': 'App & Web Development',
  'graphic-designers': 'Graphic Design',
  'growth-marketers': 'Business & Startups',
  guitarists: 'Music',
  'hair-and-makeups': 'Video & Animation',
  'harmonica-players': 'Music',
  illustrators: 'Graphic Design',
  'image-editors': 'Graphic Design',
  'influencer-marketing-managers': 'Business & Startups',
  'infographic-designers': 'Graphic Design',
  'instructional-designers': 'Business & Startups',
  'interior-designers': 'Graphic Design',
  keyboards: 'Music',
  'lead-sound-engineers': 'Music',
  lightings: 'Video & Animation',
  'line-producers': 'Video & Animation',
  'link-building-specialists': 'Digital Marketing',
  lithographers: 'Graphic Design',
  'location-managers': 'Video & Animation',
  'logo-designers': 'Graphic Design',
  lyricists: 'Music',
  marketers: 'Digital Marketing',
  'marketing-analysts': 'Digital Marketing',
  'marketing-automation-specialists': 'Business & Startups',
  'marketing-strategists': 'Digital Marketing',
  'mastering-engineers': 'Music',
  'media-coordinators': 'Video & Animation',
  merchandisers: 'Digital Marketing',
  'mixing-engineers': 'Music',
  'monogram-designers': 'Graphic Design',
  'motion-graphic-designers': 'Video & Animation',
  'music-composers': 'Music',
  'music-editors': 'Music',
  'music-producers': 'Music',
  'music-supervisors': 'Video & Animation',
  musicians: 'Music',
  oboists: 'Music',
  'online-trainers': 'Business & Startups',
  orchestras: 'Music',
  orchestrators: 'Music',
  organists: 'Music',
  'packaging-designers': 'Graphic Design',
  percussions: 'Music',
  'performance-marketers': 'Digital Marketing',
  photographers: 'Video & Animation',
  'photoshop-editors': 'Graphic Design',
  pianists: 'Music',
  'piano-trios': 'Music',
  'pitch-deck-designers': 'Graphic Design',
  'podcast-editors': 'Business & Startups',
  'podcast-producers': 'Music',
  poets: 'Video & Animation',
  'post-production-coordinators': 'Video & Animation',
  'post-production-supervisors': 'Video & Animation',
  'post-productions': 'Video & Animation',
  'poster-designers': 'Graphic Design',
  'ppc-managers': 'Digital Marketing',
  'pr-freelancers': 'Digital Marketing',
  'presentation-designers': 'Graphic Design',
  presenters: 'Video & Animation',
  'product-designers': 'App & Web Development',
  'product-marketers': 'Business & Startups',
  'production-assistants': 'Video & Animation',
  'production-coordinators': 'Video & Animation',
  'production-designers': 'Graphic Design',
  'production-managers': 'Video & Animation',
  programmers: 'App & Web Development',
  'project-managers': 'App & Web Development',
  props: 'Video & Animation',
  'qa-engineers': 'App & Web Development',
  'rap-artists': 'Music',
  'recording-studios': 'Music',
  saxophonists: 'Music',
  'scamp-artists': 'Graphic Design',
  'screenplay-writers': 'Video & Animation',
  screenwriters: 'Video & Animation',
  'script-supervisors': 'Video & Animation',
  'seo-experts': 'Digital Marketing',
  'session-artists': 'Music',
  'set-designers': 'Video & Animation',
  singers: 'Music',
  sitars: 'Music',
  'snare-drum-players': 'Music',
  'social-media-freelancers': 'Digital Marketing',
  'social-media-managers': 'Digital Marketing',
  songwriters: 'Music',
  'sound-designers': 'Music',
  'sound-editors': 'Music',
  'sound-engineers': 'Music',
  'sound-technicians': 'Music',
  'soundtrack-musicians': 'Music',
  stagehands: 'Video & Animation',
  'story-producers': 'Video & Animation',
  'storyboard-artists': 'Graphic Design',
  'string-quartets': 'Music',
  strings: 'Music',
  'studio-managers': 'Music',
  'studio-owners': 'Music',
  'stunt-coordinators': 'Video & Animation',
  'stunt-performers': 'Video & Animation',
  stylists: 'Video & Animation',
  't-shirt-designers': 'Graphic Design',
  'tattoo-illustrators': 'Graphic Design',
  'textile-designers': 'Graphic Design',
  trombonists: 'Music',
  trumpeters: 'Music',
  'ugc-creators': 'Business & Startups',
  'ui-designers': 'App & Web Development',
  'ux-designers': 'App & Web Development',
  'ux-writers': 'Digital Marketing',
  'vector-illustrators': 'Graphic Design',
  'vfx-supervisors': 'Video & Animation',
  vibraphones: 'Music',
  'video-editors': 'Video & Animation',
  videographers: 'Video & Animation',
  violinists: 'Music',
  'visual-effects': 'Video & Animation',
  vocalists: 'Music',
  'voiceover-artists': 'Video & Animation',
  'vui-developers': 'App & Web Development',
  'wardrobe-supervisors': 'Video & Animation',
  wardrobes: 'Video & Animation',
  'web-designers': 'App & Web Development',
  'web-developers': 'App & Web Development',
  writers: 'Video & Animation',
};

export const getRoleCategory = (roleName) => {
  return roleCategories[toSeoUrl(toPlural(roleName))];
};

const roleCategoryFooterHeadingLink = {
  'App & Web Development': '/hire-a-freelancer/programming',
  AI: '/ai',
  'Business & Startups': '/hire-a-freelancer/business',
  'Digital Marketing': '/hire-a-freelancer/digital-marketing',
  'Graphic Design': '/hire-a-freelancer/graphic-design',
  Music: '/hire-a-freelancer/music',
  'Video & Animation': '/hire-a-freelancer/video-and-animation',
};

export const getRoleCategoryFooterHeadingLink = (category) => {
  return roleCategoryFooterHeadingLink[category] || '/hire-a-freelancer';
};

export const singularRoleNames = {
  Actress: 'Actress',
  Actresses: 'Actress',
  Strings: 'Strings',
  Props: 'Props',
  Keyboards: 'Keyboards',
};
