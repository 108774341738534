import React from 'react';
import styles from '../Footer.module.scss';

const ForFreelancers = () => {
  return (
    <ul className={styles.footerList}>
      <li className={styles.subTitle}>
        <a href="/freelancers">
          <strong>For Freelancers</strong>
        </a>
      </li>
      <li>
        <a href="/freelance/creatives">Find Creative Jobs</a>
      </li>
      <li>
        <a href="/freelance/developers">Find Developers Jobs</a>
      </li>
      <li>
        <a href="/freelance/marketing">Find Marketing Jobs</a>
      </li>
      <li>
        <a href="/freelance/music">Find Music Jobs</a>
      </li>
      <li>
        <a href="/jobs">See All Freelance Jobs</a>
      </li>
    </ul>
  );
};

export default ForFreelancers;
