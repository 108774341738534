/**
 * THIS IS THE ENTRY POINT FOR THE CLIENT, JUST LIKE server.js IS THE ENTRY POINT FOR THE SERVER.
 */
import React from 'react';
import { hydrate } from 'react-dom';
import * as Sentry from '@sentry/react';
import { captureConsoleIntegration } from '@sentry/integrations';
import { Provider } from 'react-redux';
import { ReduxAsyncConnect } from 'redux-connect';
import { ConnectedRouter } from 'connected-react-router';
import { loadableReady } from '@loadable/component';
import getRoutes from './routes/routes';
import ApiClient from './helpers/ApiClient';
import createStore from './redux/create';
import { PostHogProvider } from 'posthog-js/react';

if (
  typeof process.env.FRONTEND_SENTRY_ENABLED !== 'undefined' &&
  typeof process.env.SENTRY_DSN !== 'undefined' &&
  typeof process.env.SENTRY_ENVIRONMENT !== 'undefined' &&
  process.env.FRONTEND_SENTRY_ENABLED === 'true'
) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      captureConsoleIntegration({
        levels: ['error'],
      }),
    ],
    environment: process.env.SENTRY_ENVIRONMENT,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.05,
  });
}

const client = new ApiClient();

const { history, store } = createStore(null, client, window.__data);

const dest = document.getElementById('content');

const routes = getRoutes(store);

const render = () =>
  loadableReady(() =>
    hydrate(
      <PostHogProvider
        apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
        options={{
          api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
          disable_session_recording: true,
          autocapture: {
            url_allowlist: [],
          },
        }}
      >
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <ReduxAsyncConnect
              routes={routes}
              // these may not be necessary
              // helpers={{ client }}
              // filter={(item) => !item.deferred}
            />
          </ConnectedRouter>
        </Provider>
      </PostHogProvider>,
      dest
    )
  );

// if (process.env.NODE_ENV !== 'production') {
//   window.React = React; // enable debugger

//   if (
//     !dest ||
//     !dest.firstChild ||
//     !dest.firstChild.attributes ||
//     !dest.firstChild.attributes['data-react-checksum']
//   ) {
//     console.error(
//       'Server-side React render was discarded. Make sure that your initial render does not contain any client-side code.'
//     );
//   }
// }

// if (__DEVTOOLS__ && !window.devToolsExtension) {
//   const DevTools = require('./containers/DevTools/DevTools');
//   ReactDOM.render(
//     <Provider store={store} key="provider">
//       <div>
//         {component}
//         <DevTools />
//       </div>
//     </Provider>,
//     dest
//   );
// }
render();
