import briefSchema from '../schemas/briefSchema';

const LOADRELATEDJOBS = 'twineApp/jobs/LOADRELATEDJOBS';
const LOADRELATEDJOBS_SUCCESS = 'twineApp/jobs/LOADRELATEDJOBS_SUCCESS';
const LOADRELATEDJOBS_FAIL = 'twineApp/jobs/LOADRELATEDJOBS_FAIL';

const initialState = {
  role: null,
  relatedJobs: [],
  loading: false,
  loaded: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOADRELATEDJOBS:
      return {
        ...state,
        loading: true,
        loaded: false,
      };

    case LOADRELATEDJOBS_SUCCESS: {
      const currentBriefId = action.meta.briefId;
      const collaborations = action.result.collaborations;
      let filteredJobs;

      if (typeof collaborations !== 'undefined') {
        filteredJobs = collaborations.some((job) => job.id === currentBriefId)
          ? collaborations.filter((job) => job.id !== currentBriefId)
          : collaborations.slice(0, collaborations.length - 1);
      } else {
        filteredJobs = [];
      }

      return {
        ...state,
        loading: false,
        loaded: true,
        relatedJobs: filteredJobs,
      };
    }

    case LOADRELATEDJOBS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    default:
      return state;
  }
}

export function loadRelatedJobs(briefId, role, user, skill) {
  let activeString = '';
  let searchParam = '';

  if (user) {
    activeString = '&state=active';
  }

  if (role) {
    searchParam = 'role=' + role;
  } else if (skill) {
    searchParam = 'skill=' + skill;
  }

  return {
    types: [LOADRELATEDJOBS, LOADRELATEDJOBS_SUCCESS, LOADRELATEDJOBS_FAIL],
    promise: (client) =>
      client.get(
        '/notices/collaboration?' +
          searchParam +
          '&limit=6&include=user,user.avatars,user.covers,rating,voucher,invites' +
          activeString
      ),
    meta: {
      briefId: briefId,
    },
  };
}
