import PropTypes from 'prop-types';
import React, { Fragment, Component } from 'react';
import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import NavDropdown from 'react-bootstrap/lib/NavDropdown';
import Fade from 'react-bootstrap/lib/Fade';
import Badge from 'react-bootstrap/lib/Badge';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Thumbnail from 'components/Thumbnails/Thumbnail';
import LinkOrAnchor from 'components/Shared/LinkOrAnchor';
import SvgIcon from 'components/SvgIcon/SvgIcon';
import styles from './LandingHeader.module.scss';

export default class LandingHeader extends Component {
  static propTypes = {
    whiteText: PropTypes.bool,
    enterpriseVersion: PropTypes.bool,
    pricingVersion: PropTypes.bool,
    homePath: PropTypes.bool,
    creativePricing: PropTypes.bool,
    count: PropTypes.number,
    oldCount: PropTypes.number,
    loadedCount: PropTypes.bool,
    loadedOldCount: PropTypes.bool,
    unreadMessages: PropTypes.number,
    user: PropTypes.object,
    query: PropTypes.object,
  };

  static defaultProps = {
    whiteText: false,
    enterpriseVersion: false,
    pricingVersion: false,
    homePath: false,
    creativePricing: false,
    count: 0,
    oldCount: 0,
    loadedCount: false,
    loadedOldCount: false,
    unreadMessages: 0,
    query: {},
  };

  state = {
    showFixedHeader: false,
    showMobileDropdown: false,
  };

  componentDidMount() {
    document.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScroll);
  }

  toggleMobileMenu = () => {
    if (this.state.showMobileDropdown) {
      document.getElementsByTagName('body')[0].classList.remove('swipeMenu');
      document.getElementsByTagName('body')[0].classList.remove('no-scroll');
    } else {
      document.getElementsByTagName('body')[0].classList.add('swipeMenu');
      document.getElementsByTagName('body')[0].classList.add('no-scroll');
    }

    this.setState({ showMobileDropdown: !this.state.showMobileDropdown });
  };

  handleScroll = () => {
    const scrollToggle = 390;

    if (this.state.showFixedHeader && window.pageYOffset < scrollToggle) {
      this.setState({
        showFixedHeader: false,
      });
    } else if (
      !this.state.showFixedHeader &&
      window.pageYOffset >= scrollToggle
    ) {
      this.setState({
        showFixedHeader: true,
      });
    }
  };

  _mobileNoAuth = () => (
    <Fragment>
      <li>
        <Link to="/hire-a-freelancer" onClick={this.toggleMobileMenu}>
          What we do
        </Link>
      </li>
      <li>
        <Link to="/whyusetwine" onClick={this.toggleMobileMenu}>
          Why use Twine?
        </Link>
      </li>
      <li>
        <Link to="/howitworks" onClick={this.toggleMobileMenu}>
          How it works
        </Link>
      </li>
      <li>
        <Link to="/pricing" onClick={this.toggleMobileMenu}>
          Pricing
        </Link>
      </li>
      <li>
        <LinkOrAnchor
          isLink={this.props.pricingVersion}
          to={this.props.pricingVersion ? '/pricing/creative' : '/freelancers'}
        >
          {this.props.pricingVersion ? 'Upgrade to Pro' : "I'm a freelancer"}
        </LinkOrAnchor>
      </li>
      <li>
        <a href="https://help.twine.net">Help Center</a>
      </li>
      <li>
        <a href="/signin">Sign in</a>
      </li>
      <li>
        <a href="/signup">Sign up</a>
      </li>
    </Fragment>
  );

  _mobileClient = () => (
    <Fragment>
      <li>
        <Link to="/hire-a-freelancer" onClick={this.toggleMobileMenu}>
          What we do
        </Link>
      </li>
      <li>
        <Link to="/howitworks" onClick={this.toggleMobileMenu}>
          How it works
        </Link>
      </li>
      <li>
        <Link to="/messages" onClick={this.toggleMobileMenu}>
          Messages
        </Link>
      </li>
      <li>
        <Link to="/browse" onClick={this.toggleMobileMenu}>
          Browse for freelancers
        </Link>
      </li>
      <li>
        <Link to="/manage/briefs" onClick={this.toggleMobileMenu}>
          Manage Jobs
        </Link>
      </li>
      <li>
        <Link to="/shortlist" onClick={this.toggleMobileMenu}>
          Favourites
        </Link>
      </li>
      <li>
        <Link to="/notifications" onClick={this.toggleMobileMenu}>
          Notifications{' '}
          <Fade in={this.props.count + this.props.oldCount > 0}>
            <Badge
              style={{
                position: 'absolute',
                right: '5px',
                top: '10px',
                backgroundColor: '#40a0f5',
              }}
            >
              {this.props.count + this.props.oldCount}
            </Badge>
          </Fade>
        </Link>
      </li>
      <li>
        <Link to="/settings" onClick={this.toggleMobileMenu}>
          Settings
        </Link>
      </li>
      <li>
        <a href="https://help.twine.net">Help Center</a>
      </li>
      <li>
        <a href="/signout">Sign out</a>
      </li>
    </Fragment>
  );

  _mobileFreelancer = () => (
    <Fragment>
      <li>
        <Link to="/jobs" onClick={this.toggleMobileMenu}>
          Find Jobs
        </Link>
      </li>
      <li>
        <Link to="/messages" onClick={this.toggleMobileMenu}>
          Messages{' '}
          <Fade in={this.props.unreadMessages > 0}>
            <Badge
              style={{
                position: 'absolute',
                right: '5px',
                top: '10px',
                backgroundColor: '#40a0f5',
              }}
            >
              {this.props.unreadMessages}
            </Badge>
          </Fade>
        </Link>
      </li>
      <li>
        <Link
          to={this.props.user.links.main_relative}
          onClick={this.toggleMobileMenu}
        >
          Portfolio
        </Link>
      </li>
      <li>
        <a href="/projects/manage">Manage projects</a>
      </li>
      <li>
        <Link to="/collaborate" onClick={this.toggleMobileMenu}>
          Collaborate
        </Link>
      </li>
      <li>
        <Link to="/howitworks" onClick={this.toggleMobileMenu}>
          How it works
        </Link>
      </li>
      <li>
        <Link to="/browse" onClick={this.toggleMobileMenu}>
          Browse for freelancers
        </Link>
      </li>
      <li>
        <Link to="/manage/briefs" onClick={this.toggleMobileMenu}>
          Manage Jobs
        </Link>
      </li>
      <li>
        <Link to="/manage/pitches" onClick={this.toggleMobileMenu}>
          Manage Pitches
        </Link>
      </li>
      <li>
        <a href="/projects/manage">Manage Projects</a>
      </li>
      <li>
        <Link to="/shortlist" onClick={this.toggleMobileMenu}>
          Favourites
        </Link>
      </li>
      <li>
        <Link to="/notifications" onClick={this.toggleMobileMenu}>
          Notifications{' '}
          <Fade in={this.props.count + this.props.oldCount > 0}>
            <Badge
              style={{
                position: 'absolute',
                right: '5px',
                top: '10px',
                backgroundColor: '#40a0f5',
              }}
            >
              {this.props.count + this.props.oldCount}
            </Badge>
          </Fade>
        </Link>
      </li>
      <li>
        <Link to="/settings" onClick={this.toggleMobileMenu}>
          Settings
        </Link>
      </li>
      <li>
        <a href="https://help.twine.net">Help Center</a>
      </li>
      <li>
        <a href="/signout">Sign out</a>
      </li>
    </Fragment>
  );

  mobileHeader = () => {
    const { whiteText, enterpriseVersion, pricingVersion, query, user } =
      this.props;

    const { showMobileDropdown } = this.state;

    return (
      <div className={styles.staticHeaderMobile}>
        <div className="container">
          <div className={styles.logoContainer}>
            <a href="/">
              <SvgIcon
                icon="logo"
                width="100"
                height="30"
                color={whiteText ? '#fff' : '#322954'}
              />
            </a>
          </div>
          <div
            className={`${styles.hamburgerIcon} ${
              showMobileDropdown ? styles.open : ''
            } ${whiteText ? styles.whiteHamburger : ''}`}
            onClick={this.toggleMobileMenu}
          >
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
        <div
          className={`${styles.mobileMenu} ${
            showMobileDropdown ? styles.open : ''
          }`}
        >
          {!enterpriseVersion && (
            <ul>
              <li>
                <LinkOrAnchor
                  isLink={pricingVersion}
                  to={pricingVersion ? '/pricing/creative' : '/post-a-job'}
                  onClick={this.toggleMobileMenu}
                  className={`${styles.hollowButton} ${styles.whiteHollow}`}
                >
                  {pricingVersion ? 'Creative Pricing' : 'Hire a creative'}
                </LinkOrAnchor>
              </li>
              {!user
                ? this._mobileNoAuth()
                : user.type === 'seller'
                ? this._mobileFreelancer()
                : this._mobileClient()}
            </ul>
          )}
          {enterpriseVersion && (
            <ul>
              <li>
                <a
                  href={`https://jointwine.typeform.com/to/ZG2POq${
                    query.ref ? `?ref=${query.ref}` : ''
                  }`}
                  className={`${styles.hollowButton} ${styles.whiteHollow}`}
                >
                  Get in touch
                </a>
              </li>
            </ul>
          )}
        </div>
      </div>
    );
  };

  render() {
    const {
      whiteText,
      enterpriseVersion,
      pricingVersion,
      homePath,
      count,
      oldCount,
      loadedCount,
      loadedOldCount,
      unreadMessages,
      query,
      user,
      creativePricing,
    } = this.props;

    const { showFixedHeader, showMobileDropdown } = this.state;

    return (
      <div>
        <div className={`${styles.staticHeader} container`}>
          <div className={styles.logoContainer}>
            <a href="/">
              <SvgIcon
                icon="logo"
                width="100"
                height="30"
                color={whiteText ? '#fff' : '#322954'}
              />
            </a>
          </div>
          {!enterpriseVersion && (
            <ul
              className={`${styles.leftMenu} ${
                whiteText ? styles.whiteText : ''
              }`}
            >
              {!user && (
                <Fragment>
                  <li className="hidden-sm">
                    <Link to="/hire-a-freelancer">What we do</Link>
                  </li>
                  <li>
                    <Link to="/whyusetwine">Why use Twine?</Link>
                  </li>
                  <li>
                    <Link to="/howitworks">How it works</Link>
                  </li>
                  <li>
                    <Link to="/pricing">Pricing</Link>
                  </li>
                </Fragment>
              )}
              {user && user.type === 'seller' && (
                <Fragment>
                  <li>
                    <Link to="/jobs">Find Jobs</Link>
                  </li>
                  <li>
                    <Link to="/messages">
                      Messages{' '}
                      <Fade in={unreadMessages > 0}>
                        <Badge
                          style={{
                            position: 'absolute',
                            right: '-15px',
                            top: '-10px',
                            backgroundColor: '#40a0f5',
                          }}
                        >
                          {unreadMessages}
                        </Badge>
                      </Fade>
                    </Link>
                  </li>
                  <li>
                    <a href="/me">Portfolio</a>
                  </li>
                  <li>
                    <a href="/toolkits/freelancer">Toolkit</a>
                  </li>
                  <NavDropdown
                    noCaret
                    title={
                      <SvgIcon
                        style={{ marginTop: '-10px', marginBottom: '-10px' }}
                        icon="more"
                      />
                    }
                    id="nav-menu-dropdown"
                    className="hidden-xs"
                  >
                    <MenuItem className="visible-sm" href="/browse">
                      Browse for freelancers
                    </MenuItem>
                    <MenuItem href="/collaborate">Collaborate</MenuItem>
                    <MenuItem href="https://www.instagram.com/jointwine/">
                      Community
                    </MenuItem>
                    <MenuItem href="/blog">News and blog</MenuItem>
                  </NavDropdown>
                </Fragment>
              )}
              {user && user.type === 'buyer' && (
                <Fragment>
                  <li className="hidden-sm">
                    <Link to="/hire-a-freelancer">What we do</Link>
                  </li>
                  <li>
                    <Link to="/whyusetwine">Why use Twine?</Link>
                  </li>
                  <li>
                    <Link to="/howitworks">How it works</Link>
                  </li>
                  <li>
                    <Link to="/messages">
                      Messages{' '}
                      <Fade in={unreadMessages > 0}>
                        <Badge
                          style={{
                            position: 'absolute',
                            right: '-15px',
                            top: '-10px',
                            backgroundColor: '#40a0f5',
                          }}
                        >
                          {unreadMessages}
                        </Badge>
                      </Fade>
                    </Link>
                  </li>
                  <li>
                    <Link to="/manage/briefs">Manage</Link>
                  </li>
                  <li>
                    <Link to="/pricing">Pricing</Link>
                  </li>
                </Fragment>
              )}
            </ul>
          )}
          {!enterpriseVersion && (
            <ul
              className={`${styles.rightMenu} ${
                whiteText ? styles.whiteText : ''
              }`}
            >
              {(pricingVersion || creativePricing || homePath) &&
                (!user || (user && user.type === 'seller')) && (
                  <Fragment>
                    <li>
                      <a
                        href={
                          pricingVersion ? '/pricing/creative' : '/freelancers'
                        }
                        className={`${styles.hollowButton} ${
                          whiteText ? styles.whiteHollow : ''
                        }`}
                      >
                        {pricingVersion ? 'Upgrade to Pro' : "I'm a freelancer"}
                      </a>
                    </li>
                    {!(pricingVersion || creativePricing || homePath) && (
                      <li>
                        <a
                          href="/post-a-job"
                          className={`${styles.hollowButton} ${
                            whiteText ? styles.whiteHollow : ''
                          }`}
                        >
                          Hire a Freelancer
                        </a>
                      </li>
                    )}
                  </Fragment>
                )}

              {user && user.type === 'buyer' && (
                <Fragment>
                  <li>
                    <a
                      href="/post-a-job"
                      className={`${styles.hollowButton} ${
                        whiteText ? styles.whiteHollow : ''
                      }`}
                    >
                      Hire a Freelancer
                    </a>
                  </li>
                </Fragment>
              )}
              {!user && (
                <Fragment>
                  <li>
                    <a href="/signin">Sign in</a>
                  </li>
                  <li>
                    <a href="/signup">Sign up</a>
                  </li>
                </Fragment>
              )}
              {user && (
                <NavDropdown
                  noCaret
                  title={
                    <div style={{ display: '-webkit-inline-box' }}>
                      <Thumbnail
                        thumbnails={user.avatars}
                        circle
                        style={{
                          width: '40px',
                          height: '40px',
                          marginTop: '-10px',
                          marginRight: '5px',
                        }}
                      />
                      <span className="caret" />
                      <Fade
                        in={
                          loadedCount && loadedOldCount && count + oldCount > 0
                        }
                      >
                        <Badge
                          style={{
                            position: 'absolute',
                            right: '-5px',
                            top: '-5px',
                            backgroundColor: '#40a0f5',
                          }}
                        >
                          {count + oldCount}
                        </Badge>
                      </Fade>
                    </div>
                  }
                  id="nav-menu-user-dropdown"
                  className={`hidden-xs ${styles.navMenu}`}
                  pullRight
                >
                  {!user.pro && user.type === 'seller' && (
                    <Fragment>
                      <MenuItem key="upgrade-1" href="/pricing/creative">
                        <strong>Upgrade to Pro!</strong>
                      </MenuItem>
                      <MenuItem key="divider-2" divider />
                      <LinkContainer
                        key="portfolio-1"
                        to={user.links.main_relative}
                      >
                        <MenuItem>Portfolio</MenuItem>
                      </LinkContainer>
                      <MenuItem key="divider-2a" divider />
                    </Fragment>
                  )}

                  <MenuItem key="manage-jobs-1" href="/manage/briefs">
                    Manage Jobs
                  </MenuItem>
                  <MenuItem key="divider-3" divider />
                  {user && user.type === 'seller' && (
                    <Fragment>
                      <MenuItem key="manage-pitches-1" href="/manage/pitches">
                        Manage Pitches
                      </MenuItem>
                      <MenuItem key="divider-4" divider />
                      <MenuItem key="manage-projects-1" href="/projects/manage">
                        Manage Projects
                      </MenuItem>
                      <MenuItem key="divider-5" divider />
                      <MenuItem key="manage-lists-1" href="/manage/lists">
                        Manage Lists
                      </MenuItem>
                      <MenuItem key="divider-6" divider />
                    </Fragment>
                  )}

                  <MenuItem key="shortlists-1" href="/shortlist">
                    Favourites
                  </MenuItem>
                  <MenuItem key="divider-7" divider />
                  <MenuItem key="notifications-1" href="/notifications">
                    Notifications{' '}
                    {count + oldCount > 0 && (
                      <Badge
                        style={{
                          position: 'absolute',
                          right: '-10px',
                          backgroundColor: '#40a0f5',
                        }}
                      >
                        {count + oldCount}
                      </Badge>
                    )}
                  </MenuItem>
                  <MenuItem key="divider-8" divider />
                  <MenuItem key="settings-1" href="/settings">
                    Settings
                  </MenuItem>
                  <MenuItem key="divider-9" divider />
                  <MenuItem key="help-center-1" href="https://help.twine.net">
                    Help Center
                  </MenuItem>
                  <MenuItem key="divider-10" divider />
                  <MenuItem key="sign-out-1" href="/signout">
                    Sign out
                  </MenuItem>
                  {typeof user.admin_user_id !== 'undefined' && (
                    <Fragment>
                      <MenuItem divider key="admin-switch-back-1" />
                      <MenuItem
                        href="/adminSwitchBack.php"
                        key="admin-switch-back-2"
                      >
                        Admin Switch Back
                      </MenuItem>
                    </Fragment>
                  )}
                </NavDropdown>
              )}
            </ul>
          )}
          {enterpriseVersion && (
            <ul
              className={`${styles.rightMenu} ${
                whiteText ? styles.whiteText : ''
              }`}
            >
              <li>
                <a
                  href={`https://jointwine.typeform.com/to/ZG2POq${
                    query.ref ? `?ref=${query.ref}` : ''
                  }`}
                  className={`${styles.hollowButton} ${
                    whiteText ? styles.whiteHollow : ''
                  }`}
                >
                  Get in touch
                </a>
              </li>
            </ul>
          )}
        </div>
        {showFixedHeader && (
          <TransitionGroup>
            <CSSTransition
              classNames="animate-fixed-header"
              timeout={{ enter: 200, exit: 200 }}
            >
              <div className={styles.fixedHeader}>
                <div className="container">
                  <div
                    className={`${styles.logoContainer} ${styles.fixedLogo}`}
                  >
                    <a href="/">
                      <SvgIcon
                        icon="logo"
                        width="100"
                        height="30"
                        color="#322954"
                      />
                    </a>
                  </div>
                  {!enterpriseVersion && (
                    <a href="/post-a-job" className={styles.hollowButton}>
                      Hire a freelancer
                    </a>
                  )}
                  {enterpriseVersion && (
                    <a
                      href={`https://jointwine.typeform.com/to/ZG2POq?ref=${
                        query.ref || ''
                      }`}
                      className={styles.hollowButton}
                    >
                      Get in touch
                    </a>
                  )}
                </div>
              </div>
            </CSSTransition>
          </TransitionGroup>
        )}
        {this.mobileHeader()}
        {showMobileDropdown && (
          <div
            className={styles.mobileOverlay}
            onClick={this.toggleMobileMenu}
          />
        )}
      </div>
    );
  }
}
