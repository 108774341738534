import React from 'react';
import styles from '../Footer.module.scss';

const TwineNetwork = () => {
  return (
    <ul className={styles.footerList}>
      <li className={styles.subTitle}>
        <a href="https://www.twine.net/">
          <strong>Twine Network</strong>
        </a>
      </li>
      <li>
        <a href="/about">About Twine</a>
      </li>
      <li>
        <a href="/ai">Twine AI</a>
      </li>
      <li>
        <a href="/advertise">Advertise</a>
      </li>
      <li>
        <a href="/contact">Support</a>
      </li>
      <li>
        <a href="/signin">Log in</a>
      </li>
      <li>
        <a href="/signup">Sign up</a>
      </li>
    </ul>
  );
};

export default TwineNetwork;
