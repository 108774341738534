import React from 'react';
import PropTypes from 'prop-types';
import styles from '../Footer.module.scss';

const Resources = ({ isClient }) => {
  return (
    <ul className={styles.footerList}>
      <li className={styles.subTitle}>
        <a href="/hire-a-freelancer/programming">
          <strong>Resources</strong>
        </a>
      </li>
      {!isClient && (
        <li>
          <a href="/freelancers">For Freelancers</a>
        </li>
      )}
      <li>
        <a href="https://help.twine.net/">Help &amp; FAQs</a>
      </li>
      <li>
        <a href="/enterprise">For Business &amp; Enterprise</a>
      </li>
      <li>
        <a href="/ai">For AI and Data Scientists</a>
      </li>
      <li>
        <a href="/freelance/one-page-website">One-Page Website</a>
      </li>
      <li>
        <a href="/toolkits/freelancer">Freelancer Toolkit</a>
      </li>
      <li>
        <a href="/toolkits/startup">Startup Toolkit</a>
      </li>
      <li>
        <a href="/blog/">News and blog</a>
      </li>
    </ul>
  );
};

Resources.propTypes = {
  isClient: PropTypes.bool,
};

export default Resources;
