import React from 'react';
import styles from '../Footer.module.scss';

const FindWork = () => {
  return (
    <ul className={styles.footerList}>
      <li className={styles.subTitle}>
        <a href="/jobs">
          <strong>Find Work</strong>
        </a>
      </li>
      <li>
        <a href="/freelancers">How to Find Work</a>
      </li>
      <li>
        <a href="/freelance/creatives">Find Creative Jobs</a>
      </li>
      <li>
        <a href="/freelance/developers">Find Developers Jobs</a>
      </li>
      <li>
        <a href="/freelance/marketing">Find Marketing Jobs</a>
      </li>
      <li>
        <a href="/jobs">See All Freelance Jobs</a>
      </li>
    </ul>
  );
};

export default FindWork;
