import React from 'react';
import PropTypes from 'prop-types';

// Third Party
import { Button } from 'react-bootstrap';

export default function NavBarCTA({
  displayUpsellButton,
  displayApplyButton,
  displaySignUpButton,
  displayPostJobButton,
  displaySignUpWithRedirect,
}) {
  const handleApplyButton = () => {
    const element = document.getElementById('jobDetailsPitchSection');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const upsellButton = (
    <Button bsStyle="primary" href={'/pricing/creative'}>
      Upgrade to Pro
    </Button>
  );

  const applyButton = (
    <Button bsStyle="primary" onClick={() => handleApplyButton()}>
      Apply
    </Button>
  );

  const signUpButton = (
    <Button bsStyle="primary" href={'/signup'}>
      Sign Up
    </Button>
  );

  const postJobButton = (
    <Button bsStyle="primary" href={'/post-a-job'}>
      Post a Job
    </Button>
  );

  return (
    <>
      {/* The Logic to display any of these is currently handled in the Sticky header  */}
      {displayUpsellButton && upsellButton}
      {displayApplyButton && applyButton}
      {displaySignUpButton && signUpButton}
      {/* The following logic to handle the render of the following buttons will be done in the header,*/}
      {displayPostJobButton && postJobButton}
      {displaySignUpWithRedirect && signUpButtonWithRedirect}
    </>
  );
}

NavBarCTA.propTypes = {
  displayUpsellButton: PropTypes.bool.isRequired,
  displayApplyButton: PropTypes.bool.isRequired,
  displaySignUpButton: PropTypes.bool.isRequired,
  displayPostJobButton: PropTypes.bool.isRequired,
  displaySignUpWithRedirect: PropTypes.bool.isRequired,
};

NavBarCTA.defaultProps = {
  displayUpsellButton: false,
  displayApplyButton: false,
  displaySignUpButton: false,
  displayPostJobButton: false,
  displaySignUpWithRedirect: false,
};
