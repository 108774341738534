import React from 'react';
import PropTypes from 'prop-types';

const MusicAudioSkills = ({ type }) => {
  if (type === 'freelancer') {
    return (
      <>
        <li>
          <a href="/jobs/music-composers">Music Composer Jobs</a>
        </li>
        <li>
          <a href="/jobs/music-producers">Music Producer Jobs</a>
        </li>
        <li>
          <a href="/jobs/mixing-engineers">Mixing Engineer Jobs</a>
        </li>
        <li>
          <a href="/jobs/mastering-engineers">Mastering Engineer Jobs</a>
        </li>
        <li>
          <a href="/jobs/audio-engineers">Audio Engineer Jobs</a>
        </li>
        <li>
          <a href="/jobs/sound-designers">Sound Designer Jobs</a>
        </li>
        <li>
          <a href="/jobs/voiceover-artists">Voiceover Artist Jobs</a>
        </li>
        <li>
          <a href="/jobs/musicians">Musician Jobs</a>
        </li>
      </>
    );
  }

  return (
    <>
      <li>
        <a href="/find/music-composers">Music Composers</a>
      </li>
      <li>
        <a href="/find/music-producers">Music Producers</a>
      </li>
      <li>
        <a href="/find/mixing-engineers">Mixing Engineers</a>
      </li>
      <li>
        <a href="/find/mastering-engineers">Mastering Engineers</a>
      </li>
      <li>
        <a href="/find/audio-engineers">Audio Engineers</a>
      </li>
      <li>
        <a href="/find/sound-designers">Sound Designers</a>
      </li>
      <li>
        <a href="/find/voiceover-artists">Voiceover Artists</a>
      </li>
      <li>
        <a href="/find/podcast-producers">Podcast Producers</a>
      </li>
      <li>
        <a href="/find/musicians">Musicians</a>
      </li>
      <li>
        <a href="/find/film-composers">Film Composers</a>
      </li>
    </>
  );
};

MusicAudioSkills.propTypes = {
  type: PropTypes.string.isRequired,
};

export default MusicAudioSkills;
