import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Grid, Col, Row } from 'react-bootstrap';
import Comparison from 'components/Footer/FooterSections/Comparison';
import HireExperts from 'components/Footer/FooterSections/HireExperts';
import FindWork from 'components/Footer/FooterSections/FindWork';
import ForFreelancers from 'components/Footer/FooterSections/ForFreelancers';
import ForClients from 'components/Footer/FooterSections/ForClients';
import LocationJobs from 'components/Footer/FooterSections/LocationJobs';
import Resources from 'components/Footer/FooterSections/Resources';
import Socials from 'components/Footer/FooterSections/Socials';
import TwineNetwork from 'components/Footer/FooterSections/TwineNetwork';
import { getRoleCategoryFooterHeadingLink } from 'helpers/Constants';

import styles from './Footer.module.scss';

const DynamicFooter = ({
  getSkillsSection,
  inverse = false,
  type = 'client',
}) => {
  const [skillsSectionCategory, setSkillsSectionCategory] = useState(null);
  const selectedRoleCategoryState = useSelector(
    (state) => state.selectedRoleCategory
  );
  const { category } = selectedRoleCategoryState;

  useEffect(() => {
    setSkillsSectionCategory(category);
  }, [category]);

  const isClient = type === 'client';

  const skillsHeading = isClient ? (
    <a href={getRoleCategoryFooterHeadingLink(skillsSectionCategory)}>
      <strong>In Demand Roles</strong>
    </a>
  ) : (
    <a href="/jobs">
      <strong>In Demand Skills</strong>
    </a>
  );

  return (
    <footer
      className={`${inverse ? styles.inverse : ''} ${styles.container} ${
        styles.largeFooter
      }`}
    >
      <nav className={`${styles.container} ${styles.largeFooterNav}`}>
        <Grid>
          <Row>
            <Col xs={12} sm={4}>
              {isClient ? <HireExperts isClient={isClient} /> : <FindWork />}
            </Col>
            <Col xs={12} sm={4}>
              <ul className={styles.footerList}>
                <li className={styles.subTitle}>{skillsHeading}</li>
                {getSkillsSection(type, skillsSectionCategory)}
                {isClient && (
                  <li>
                    <a href="/hire-a-freelancer">
                      <strong>See More Skills</strong>
                    </a>
                  </li>
                )}
              </ul>
            </Col>
            <Col xs={12} sm={4}>
              <Resources isClient={isClient} />
            </Col>
          </Row>
          <Row className="mt++">
            <Col xs={12} sm={4}>
              {isClient ? <ForFreelancers /> : <ForClients />}
            </Col>
            <Col xs={12} sm={4}>
              {isClient ? <Comparison /> : <LocationJobs />}
            </Col>
            <Col xs={12} sm={4}>
              <TwineNetwork />
            </Col>
          </Row>
          <Row className="mt++">
            <Col xs={12} className={styles.footerBottom}>
              <Socials />
            </Col>
          </Row>
        </Grid>
      </nav>
    </footer>
  );
};

DynamicFooter.propTypes = {
  getSkillsSection: PropTypes.func,
  inverse: PropTypes.bool,
  type: PropTypes.oneOf(['client', 'freelancer']),
};

export default memo(DynamicFooter);
