import React from 'react';
import styles from '../Footer.module.scss';

const ForClients = () => {
  return (
    <ul className={styles.footerList}>
      <li className={styles.subTitle}>
        <a href="/hire-a-freelancer">
          <strong>For Clients</strong>
        </a>
      </li>
      <li>
        <a href="/howitworks">How it Works</a>
      </li>
      <li>
        <a href="/post-a-job">Post a Project</a>
      </li>
      <li>
        <a href="/hire-a-freelancer/video-and-animation">
          Hire Video &amp; Animation Experts
        </a>
      </li>
      <li>
        <a href="/hire-a-freelancer/programming">Hire Developers</a>
      </li>
      <li>
        <a href="/hire-a-freelancer/graphic-design">Hire Designers</a>
      </li>
      <li>
        <a href="/hire-a-freelancer/music">Hire Music &amp; Audio Experts</a>
      </li>
      <li>
        <a href="/hire-a-freelancer/digital-marketing">
          Hire Marketing Experts
        </a>
      </li>
      <li>
        <a href="/hire-a-freelancer">See More Freelancer Skills</a>
      </li>
    </ul>
  );
};

export default ForClients;
