import React from 'react';

import { asyncConnect } from 'redux-connect';
import { fromJS } from 'immutable';
import {
  shortlist,
  isBriefsLoaded,
  isOwnBriefsLoaded,
  isCreditsLoaded,
  isShortlisted,
  isUserLoaded,
  loadBriefs,
  loadOwnBriefs,
  loadCredits,
  loadUserTour,
  loadTestimonials,
  loadUser,
  reorderCredits,
  unshortlist,
  verify,
  updateUserTour,
} from 'redux/modules/portfolio';
import {
  acceptPitch,
  connectPayPal,
  inviteUser,
  getPaymentToken,
} from 'redux/modules/briefDetails';
import { loadPendingPitches } from 'redux/modules/messages';
import {
  isLoaded as isSuggestedRolesLoaded,
  load as loadSuggestedRoles,
} from 'redux/modules/suggestedRoles';
import {
  isLoaded as isSuggestedSkillsLoaded,
  load as loadSuggestedSkills,
} from 'redux/modules/suggestedSkills';
import { sendUserReport } from 'redux/modules/reports';
import { setSuccessMessage } from 'redux/modules/successMessage';
import {
  setSelectedRoleCategory,
  clearSelectedRoleCategory,
} from 'redux/modules/selectedRoleCategory';
import {
  update as updateUser,
  updateLocal as updateLocalUser,
} from 'redux/modules/auth';
import { loadPartners } from 'redux/modules/partners';

import loadable from '@loadable/component';

const PortfolioBase = loadable(() => import('./PortfolioBase'));
const Portfolio = asyncConnect(
  [
    {
      promise: ({
        store: { dispatch, getState },
        match: {
          params: { username },
        },
      }) => {
        const promises = [];

        if (!isUserLoaded(getState(), username)) {
          promises.push(dispatch(loadUser(username)));
        }

        return Promise.all(promises);
      },
    },
    {
      promise: ({
        store: { dispatch, getState },
        match: {
          params: { username },
        },
      }) => {
        const promises = [];

        if (!isBriefsLoaded(getState(), username)) {
          promises.push(dispatch(loadBriefs(username)));
        }

        return Promise.all(promises);
      },
    },
    {
      promise: ({
        store: { dispatch, getState },
        match: {
          params: { username },
        },
      }) => {
        const promises = [];

        if (!isCreditsLoaded(getState(), username)) {
          promises.push(dispatch(loadCredits(username)));
        }

        return Promise.all(promises);
      },
    },
  ],
  (state) => ({
    user: state.auth.user,
    briefEntities: fromJS(state.entities.briefs),
    creditEntities: fromJS(state.entities.credits),
    portfolio: fromJS(state.portfolio),
    projectEntities: fromJS(state.entities.projects),
    roleEntities: fromJS(state.entities.roles),
    sendingUserReport: fromJS(state.reports.sendingUserReport),
    sentUserReports: fromJS(state.reports.sentUserReports),
    testimonialEntities: fromJS(state.entities.testimonials),
    userEntities: fromJS(state.entities.users),
    voucherEntities: state.entities.vouchers,
    paymentToken: state.briefDetails.paymentToken,
    pitches: fromJS(state.messages.pitches),
    suggestedRoles: state.suggestedRoles.suggestedRoles,
    loadingSuggestedRoles: state.suggestedRoles.loading,
    loadedSuggestedRoles: state.suggestedRoles.loaded,
    suggestedSkills: state.suggestedSkills.suggestedSkills,
    loadingSuggestedSkills: state.suggestedSkills.loading,
    loadedSuggestedSkills: state.suggestedSkills.loaded,
    portfolioCarousel: state.landingPages.portfolioCarousel,
    updatingUser: state.auth.updating,
    loadingPartners: state.partners.loadingPartners,
    loadedPartners: state.partners.loadedPartners,
    partners: state.partners.partners,
  }),
  {
    acceptPitch,
    connectPayPal,
    shortlist,
    getPaymentToken,
    inviteUser,
    isBriefsLoaded,
    isOwnBriefsLoaded,
    isCreditsLoaded,
    isShortlisted,
    isSuggestedRolesLoaded,
    isSuggestedSkillsLoaded,
    isUserLoaded,
    loadBriefs,
    loadOwnBriefs,
    loadCredits,
    loadUserTour,
    loadPendingPitches,
    loadSuggestedRoles,
    loadSuggestedSkills,
    loadTestimonials,
    loadUser,
    loadPartners,
    reorderCredits,
    sendUserReport,
    updateUserTour,
    setSelectedRoleCategory,
    clearSelectedRoleCategory,
    setSuccessMessage,
    unshortlist,
    verify,
    updateUser,
    updateLocalUser,
  }
)(PortfolioBase);

export default Portfolio;
