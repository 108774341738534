import React from 'react';
import PropTypes from 'prop-types';

const AISkills = ({ type }) => {
  if (type === 'freelancer') {
    return (
      <>
        <li>
          <a href="/jobs/ai-engineers">AI Engineer Jobs</a>
        </li>
        <li>
          <a href="/jobs/app-developers">App Developer Jobs</a>
        </li>
        <li>
          <a href="/jobs/developers">Developer Jobs</a>
        </li>
        <li>
          <a href="/jobs/front-end-developers">Front-End Developer Jobs</a>
        </li>
        <li>
          <a href="/jobs/full-stack-developers">Full Stack Developer Jobs</a>
        </li>
        <li>
          <a href="/jobs/back-end-developers">Back-End Developer Jobs</a>
        </li>
        <li>
          <a href="/jobs/data-scientists">Data Scientists Jobs</a>
        </li>
        <li>
          <a href="/jobs/database-developers">Database Developer Jobs</a>
        </li>
        <li>
          <a href="/jobs/blockchain-developers">Blockchain Developer Jobs</a>
        </li>
      </>
    );
  }

  return (
    <>
      <li>
        <a href="/find/ai-engineers">AI Engineers</a>
      </li>
      <li>
        <a href="/find/data-scientists">Data Scientists</a>
      </li>
      <li>
        <a href="/find/developers/with/python">Python Developers</a>
      </li>
      <li>
        <a href="/find/ai-strategy-consultants">AI Strategy Consultants</a>
      </li>
      <li>
        <a href="/find/ai-ethics-consultants">AI Ethics Consultants</a>
      </li>
      <li>
        <a href="/find/qa-engineers">QA Engineers</a>
      </li>
      <li>
        <a href="/find/audio-transcribers">Audio Transcribers</a>
      </li>
      <li>
        <a href="/find/ai-engineers/with/pytorch">PyTorch Developers</a>
      </li>
      <li>
        <a href="/find/back-end-developers">Back-end Developers</a>
      </li>
      <li>
        <a href="/find/cloud-developers">Cloud Developers</a>
      </li>
    </>
  );
};

AISkills.propTypes = {
  type: PropTypes.string.isRequired,
};

export default AISkills;
