const SET_SELECTED_ROLE_CATEGORY =
  'twineApp/selectedRoleCategory/SET_SELECTED_ROLE_CATEGORY';
const CLEAR_SELECTED_ROLE = 'twineApp/selectedRoleCategory/CLEAR_SELECTED_ROLE';

const initialState = {
  category: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_SELECTED_ROLE_CATEGORY:
      return {
        ...state,
        category: action.category,
      };
    case CLEAR_SELECTED_ROLE:
      return initialState;
    default:
      return state;
  }
}

export function setSelectedRoleCategory(category) {
  return {
    type: SET_SELECTED_ROLE_CATEGORY,
    category,
  };
}

export function clearSelectedRoleCategory() {
  return {
    type: CLEAR_SELECTED_ROLE,
  };
}
