import React from 'react';
import PropTypes from 'prop-types';

const DesignSkills = ({ type }) => {
  if (type === 'freelancer') {
    return (
      <>
        <li>
          <a href="/jobs/graphic-designers">Graphic Design Jobs</a>
        </li>
        <li>
          <a href="/jobs/ui-designers">UI Design Jobs</a>
        </li>
        <li>
          <a href="/jobs/ux-designers">UX Design Jobs</a>
        </li>
        <li>
          <a href="/jobs/web-designers">Web Design Jobs</a>
        </li>
        <li>
          <a href="/jobs/brand-designers">Brand Design Jobs</a>
        </li>
        <li>
          <a href="/jobs/logo-designers">Logo Design Jobs</a>
        </li>
        <li>
          <a href="/jobs/illustrators">Illustrator Jobs</a>
        </li>
        <li>
          <a href="/jobs/pitch-deck-designers">Pitch Deck Design Jobs</a>
        </li>
      </>
    );
  }

  return (
    <>
      <li>
        <a href="/find/graphic-designers">Graphic Designers</a>
      </li>
      <li>
        <a href="/find/ui-designers">UI Designers</a>
      </li>
      <li>
        <a href="/find/ux-designers">UX Designers</a>
      </li>
      <li>
        <a href="/find/web-designers">Web Designers</a>
      </li>
      <li>
        <a href="/find/brand-designers">Brand Designers</a>
      </li>
      <li>
        <a href="/find/logo-designers">Logo Designers</a>
      </li>
      <li>
        <a href="/find/illustrators">Illustrators</a>
      </li>
      <li>
        <a href="/find/pitch-deck-designers">Pitch Deck Designers</a>
      </li>
      <li>
        <a href="/find/banner-ad-designers">Banner Ad Designers</a>
      </li>
      <li>
        <a href="/find/product-designers">Product Designers</a>
      </li>
    </>
  );
};

DesignSkills.propTypes = {
  type: PropTypes.string.isRequired,
};

export default DesignSkills;
