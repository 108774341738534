import userSchema from '../schemas/userSchema';
import creditSchema from '../schemas/creditSchema';
import briefSchema from '../schemas/briefSchema';
import { jsonToFormData, mergeDeep, get } from '../../helpers/HelperFunctions';

const LOAD = 'twineApp/auth/LOAD';
const LOAD_SUCCESS = 'twineApp/auth/LOAD_SUCCESS';
const LOAD_FAIL = 'twineApp/auth/LOAD_FAIL';
const SIGNUP = 'twineApp/auth/SIGNUP';
const SIGNUP_SUCCESS = 'twineApp/auth/SIGNUP_SUCCESS';
const SIGNUP_FAIL = 'twineApp/auth/SIGNUP_FAIL';
const GOOGLEOAUTH = 'twineApp/auth/GOOGLEOAUTH';
const GOOGLEOAUTH_SUCCESS = 'twineApp/auth/GOOGLEOAUTH_SUCCESS';
const GOOGLEOAUTH_FAIL = 'twineApp/auth/GOOGLEOAUTH_FAIL';
const SENDGOOGLEOAUTHCODE = 'twineApp/auth/SENDGOOGLEOAUTHCODE';
const SENDGOOGLEOAUTHCODE_SUCCESS = 'twineApp/auth/SENDGOOGLEOAUTHCODE_SUCCESS';
const SENDGOOGLEOAUTHCODE_FAIL = 'twineApp/auth/SENDGOOGLEOAUTHCODE_FAIL';
const UPDATETOUR = 'twineApp/auth/UPDATETOUR';
const UPDATETOUR_SUCCESS = 'twineApp/auth/UPDATETOUR_SUCCESS';
const UPDATETOUR_FAIL = 'twineApp/auth/UPDATETOUR_FAIL';
const UPDATE = 'twineApp/auth/UPDATE';
const UPDATE_SUCCESS = 'twineApp/auth/UPDATE_SUCCESS';
const UPDATE_FAIL = 'twineApp/auth/UPDATE_FAIL';
const SAVEBRAINTREENONCE = 'twineApp/auth/SAVEBRAINTREENONCE';
const SAVEBRAINTREENONCE_SUCCESS = 'twineApp/auth/SAVEBRAINTREENONCE_SUCCESS';
const SAVEBRAINTREENONCE_FAIL = 'twineApp/auth/SAVEBRAINTREENONCE_FAIL';
const ADDPUSHSUBSCRIPTION = 'twineApp/auth/ADDPUSHSUBSCRIPTION';
const ADDPUSHSUBSCRIPTION_SUCCESS = 'twineApp/auth/ADDPUSHSUBSCRIPTION_SUCCESS';
const ADDPUSHSUBSCRIPTION_FAIL = 'twineApp/auth/ADDPUSHSUBSCRIPTION_FAIL';
const UPDATELOCAL = 'twineApp/auth/UPDATELOCAL';
const LOADOWNCREDITS = 'twineApp/auth/LOADOWNCREDITS';
const LOADOWNCREDITS_SUCCESS = 'twineApp/auth/LOADOWNCREDITS_SUCCESS';
const LOADOWNCREDITS_FAIL = 'twineApp/auth/LOADOWNCREDITS_FAIL';
const LOADOWNBRIEFS = 'twineApp/auth/LOADOWNBRIEFS';
const LOADOWNBRIEFS_SUCCESS = 'twineApp/auth/LOADOWNBRIEFS_SUCCESS';
const LOADOWNBRIEFS_FAIL = 'twineApp/auth/LOADOWNBRIEFS_FAIL';
const VALIDATE_EMAIL = 'twineApp/auth/VALIDATE_EMAIL';
const VALIDATE_EMAIL_SUCCESS = 'twineApp/auth/VALIDATE_EMAIL_SUCCESS';
const VALIDATE_EMAIL_FAIL = 'twineApp/auth/VALIDATE_EMAIL_FAIL';
const EMAIL_VALIDATION_DANGER = 'twineApp/auth/EMAIL_VALIDATION_DANGER';
const EMAIL_VALIDATION_WARNING = 'twineApp/auth/EMAILVALIDATION_WARNING';
const EMAIL_ERROR_RESET = 'twineApp/auth/EMAIL_ERROR_RESET';
const UNDOCK_RESET = 'twineApp/auth/UNDOCK_RESET';
const DELETEACCOUNT = 'twineApp/auth/DELETEACCOUNT';
const DELETEACCOUNT_SUCCESS = 'twineApp/auth/DELETEACCOUNT_SUCCESS';
const DELETEACCOUNT_FAIL = 'twineApp/auth/DELETEACCOUNT_FAIL';
const RESEND_EMAIL_ACTIVATION = 'twineApp/auth/RESEND_EMAIL_ACTIVATION';
const RESEND_EMAIL_ACTIVATION_SUCCESS =
  'twineApp/auth/RESEND_EMAIL_ACTIVATION_SUCCESS';
const RESEND_EMAIL_ACTIVATION_FAIL =
  'twineApp/auth/RESEND_EMAIL_ACTIVATION_FAIL';
const CREATE_AUTH_CODE_REQUEST = 'twineApp/auth/CREATE_AUTH_CODE_REQUEST';
const CREATE_AUTH_CODE_SUCCESS = 'twineApp/auth/CREATE_AUTH_CODE_SUCCESS';
const CREATE_AUTH_CODE_FAILURE = 'twineApp/auth/CREATE_AUTH_CODE_FAILURE';
const VERIFY_AUTH_CODE_REQUEST = 'twineApp/auth/VERIFY_AUTH_CODE_REQUEST';
const VERIFY_AUTH_CODE_SUCCESS = 'twineApp/auth/VERIFY_AUTH_CODE_SUCCESS';
const VERIFY_AUTH_CODE_FAILURE = 'twineApp/auth/VERIFY_AUTH_CODE_FAILURE';
const PASSWORD_SIGNIN_REQUEST = 'twineApp/auth/PASSWORD_SIGNIN_REQUEST';
const PASSWORD_SIGNIN_SUCCESS = 'twineApp/auth/PASSWORD_SIGNIN_SUCCESS';
const PASSWORD_SIGNIN_FAILURE = 'twineApp/auth/PASSWORD_SIGNIN_FAILURE';
const SIGNUP_PASSWORDLESS = 'twineApp/auth/SIGNUP_PASSWORDLESS';
const SIGNUP_PASSWORDLESS_SUCCESS = 'twineApp/auth/SIGNUP_PASSWORDLESS_SUCCESS';
const SIGNUP_PASSWORDLESS_FAIL = 'twineApp/auth/SIGNUP_PASSWORDLESS_FAIL';

const initialState = {
  loaded: false,
  loading: false,
  authError: null,
  signingUp: false,
  signedUp: false,
  updating: false,
  updatingKeys: [],
  lastUpdated: '',
  updated: false,
  updateError: [],
  passwordError: [],
  emailError: [],
  usernameError: [],
  accountTypeError: [],
  savingBraintreeNonce: false,
  addingPushSubscription: false,
  ownCredits: {
    loaded: false,
    loading: false,
    more: true,
    pagesLoaded: 0,
    credits: [],
  },
  ownBriefs: {
    loaded: false,
    loading: false,
    more: true,
    pagesLoaded: 0,
    collaborations: [],
  },
  gettingGoogleOAuthURL: false,
  gotGoogleOAuthURL: false,
  signingUpViaGoogle: false,
  signedUpToGoogle: false,
  userId: undefined,
  emailValid: true,
  emailValidationErorr: '',
  emailValidationErrorType: 'invalid',
  emailValidationWarningClass: 'emailWarning',
  googleSignUpFailed: false,
  authCodeResponse: null,
  verifyAuthCodeResponse: null,
  verifyingAuthCode: false,
  verifiedAuthCode: false,
  verifyAuthCodeError: null,
  errorStatus: null,
  passwordSignInLoading: false,
  passwordSignInError: null,
  passwordSignInResponse: null,
  signUpPasswordlessLoading: false,
  signUpPasswordlessError: null,
  signUpPasswordlessResponse: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true,
      };

    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        user: action.result,
        authError: null,
      };

    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        authError: action.error,
      };

    case SIGNUP:
      return {
        ...state,
        signingUp: true,
        passwordError: [],
        emailError: [],
        usernameError: [],
        accountTypeError: [],
      };

    case SIGNUP_SUCCESS:
      return {
        ...state,
        signedUp: true,
        signingUp: false,
      };

    case SIGNUP_FAIL:
      return {
        ...state,
        signingUp: false,
        passwordError: action.error.errors.filter((error) =>
          error[0].includes('password')
        ),
        emailError: action.error.errors.filter((error) =>
          error[0].includes('email')
        ),
        usernameError: action.error.errors.filter((error) =>
          error[0].includes('username')
        ),
        accountTypeError: action.error.errors.filter((error) =>
          error[0].includes('accountType')
        ),
      };

    case GOOGLEOAUTH:
      return {
        ...state,
        gettingGoogleOAuthURL: true,
      };

    case GOOGLEOAUTH_SUCCESS:
      if (action.result.authUrl) {
        window.location.replace(action.result.authUrl);
      }
      return {
        ...state,
        gettingGoogleOAuthURL: false,
        gotGoogleOAuthURL: true,
        googleSignUpFailed: false,
      };

    case GOOGLEOAUTH_FAIL:
      if (action.result.authUrl) {
        window.location.replace(action.result.authUrl);
      }
      return {
        ...state,
        gettingGoogleOAuthURL: false,
        gotGoogleOAuthURL: false,
        googleSignUpFailed: true,
      };

    case SENDGOOGLEOAUTHCODE:
      return {
        ...state,
        signingUpViaGoogle: true,
      };

    case SENDGOOGLEOAUTHCODE_SUCCESS:
      return {
        ...state,
        signingUpViaGoogle: false,
        signedUpToGoogle: true,
      };

    case SENDGOOGLEOAUTHCODE_FAIL:
      return {
        ...state,
        signingUpViaGoogle: false,
        signedUpToGoogle: false,
      };

    case UPDATE:
      return {
        ...state,
        updating: true,
        lastUpdated: action.lastUpdated,
        updatingKeys:
          action.updatingKey !== ''
            ? get(state, ['updatingKeys'], []).concat([action.updatingKey])
            : get(state, ['updatingKeys'], []),
      };

    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        updated: true,
        updatingKeys: get(state, ['updatingKeys'], []).filter(
          (element) => element !== action.updatingKey
        ),
        user: mergeDeep(state.user, action.entities.users[action.userId]),
      };

    case UPDATE_FAIL:
      return {
        ...state,
        updating: false,
        updated: true,
        updateError: action.error,
        updatingKeys: get(state, ['updatingKeys'], []).filter(
          (element) => element !== action.updatingKey
        ),
      };

    case UPDATETOUR:
      return {
        ...state,
        user: {
          ...state.user,
          tour: {
            ...state.user.tour,
            [action.field]: true,
          },
        },
      };

    case SAVEBRAINTREENONCE:
      return {
        ...state,
        savingBraintreeNonce: true,
      };

    case SAVEBRAINTREENONCE_SUCCESS:
      return {
        ...state,
        savingBraintreeNonce: false,
      };

    case SAVEBRAINTREENONCE_FAIL:
      return {
        ...state,
        savingBraintreeNonce: false,
      };

    case ADDPUSHSUBSCRIPTION:
      return {
        ...state,
        addingPushSubscription: true,
      };

    case ADDPUSHSUBSCRIPTION_SUCCESS:
      return {
        ...state,
        addingPushSubscription: false,
      };

    case ADDPUSHSUBSCRIPTION_FAIL:
      return {
        ...state,
        addingPushSubscription: false,
      };

    case UPDATELOCAL:
      return {
        ...state,
        user: mergeDeep(state.user, action.fields),
      };

    case LOADOWNCREDITS:
      return {
        ...state,
        ownCredits: {
          ...state.ownCredits,
          loading: true,
        },
      };

    case LOADOWNCREDITS_SUCCESS:
      return {
        ...state,
        ownCredits: {
          ...state.ownCredits,
          loading: false,
          loaded: true,
          more:
            Array.isArray(action.result) && action.result.length === 0
              ? false
              : get(
                  action,
                  ['result', 'meta', 'pagination', 'current_page'],
                  0
                ) <
                get(action, ['result', 'meta', 'pagination', 'total_pages'], 0),
          pagesLoaded: state.ownCredits.pagesLoaded + 1,
          credits:
            Array.isArray(action.result) && action.result.length === 0
              ? state.ownCredits.credits
              : state.ownCredits.credits.concat(action.result.credits),
        },
      };

    case LOADOWNCREDITS_FAIL:
      return {
        ...state,
        ownCredits: {
          ...state.ownCredits,
          loading: false,
        },
      };

    case LOADOWNBRIEFS:
      return {
        ...state,
        ownBriefs: {
          ...state.ownBriefs,
          loading: true,
        },
      };

    case LOADOWNBRIEFS_SUCCESS:
      return {
        ...state,
        ownBriefs: {
          ...state.ownBriefs,
          loading: false,
          loaded: true,
          more:
            Array.isArray(action.result) && action.result.length === 0
              ? false
              : get(
                  action,
                  ['result', 'meta', 'pagination', 'current_page'],
                  0
                ) <
                get(action, ['result', 'meta', 'pagination', 'total_pages'], 0),
          pagesLoaded: state.ownBriefs.pagesLoaded + 1,
          collaborations:
            Array.isArray(action.result) && action.result.length === 0
              ? state.ownBriefs.collaborations
              : state.ownBriefs.collaborations.concat(
                  action.result.collaborations
                ),
        },
      };

    case LOADOWNBRIEFS_FAIL:
      return {
        ...state,
        ownBriefs: {
          ...state.ownBriefs,
          loading: false,
        },
      };

    case VALIDATE_EMAIL:
      return {
        ...state,
        emailValid: true,
      };

    case VALIDATE_EMAIL_SUCCESS:
      return {
        ...state,
        emailValid: action.result.valid,
        emailValidationError: action.result.error,
        emailValidationType: action.result.status,
      };

    case VALIDATE_EMAIL_FAIL:
      return {
        ...state,
        emailValid: true,
      };

    case EMAIL_VALIDATION_DANGER:
      return {
        ...state,
        emailValidationWarningClass: 'emailDanger',
      };

    case EMAIL_VALIDATION_WARNING:
      return {
        ...state,
        emailValidationWarningClass: 'emailWarning',
      };

    case EMAIL_ERROR_RESET:
      return {
        ...state,
        emailError: [],
      };

    case UNDOCK_RESET:
      return {
        ...state,
        user: {
          ...state.user,
          undock: [],
        },
      };
    case DELETEACCOUNT:
      return {
        ...state,
        updating: true,
      };

    case DELETEACCOUNT_SUCCESS:
      return {
        ...state,
        updating: false,
        updated: true,
      };

    case DELETEACCOUNT_FAIL:
      return {
        ...state,
        updating: false,
        updated: true,
        updateError: action.error,
      };

    case RESEND_EMAIL_ACTIVATION:
      return {
        ...state,
        updating: true,
      };

    case RESEND_EMAIL_ACTIVATION_SUCCESS:
      return {
        ...state,
        updating: false,
        updated: true,
      };

    case RESEND_EMAIL_ACTIVATION_FAIL:
      return {
        ...state,
        updating: false,
        updated: true,
        updateError: action.error,
      };

    case CREATE_AUTH_CODE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: null,
        errorStatus: null,
        authCodeResponse: null,
      };
    }

    case CREATE_AUTH_CODE_SUCCESS: {
      return {
        ...state,
        loading: false,
        authCodeResponse: action.result,
      };
    }

    case CREATE_AUTH_CODE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error && action.error.message,
        errorStatus: action.error && action.error.status_code,
      };
    }

    case VERIFY_AUTH_CODE_REQUEST: {
      return {
        ...state,
        verifyingAuthCode: true,
        verifiedAuthCode: false,
        verifyAuthCodeError: null,
      };
    }

    case VERIFY_AUTH_CODE_SUCCESS: {
      return {
        ...state,
        verifyingAuthCode: false,
        verifiedAuthCode: true,
        verifyAuthCodeResponse: action.result,
      };
    }

    case VERIFY_AUTH_CODE_FAILURE: {
      return {
        ...state,
        verifyingAuthCode: false,
        verifiedAuthCode: true,
        verifyAuthCodeError: action.error && action.error.message,
      };
    }

    case PASSWORD_SIGNIN_REQUEST: {
      return {
        ...state,
        passwordSignInLoading: true,
        passwordSignInError: null,
      };
    }

    case PASSWORD_SIGNIN_SUCCESS: {
      return {
        ...state,
        passwordSignInLoading: false,
        passwordSignInResponse: action.result,
        passwordSignInError: null,
      };
    }

    case PASSWORD_SIGNIN_FAILURE: {
      return {
        ...state,
        passwordSignInLoading: false,
        passwordSignInError: action.error && action.error.message,
      };
    }

    case SIGNUP_PASSWORDLESS: {
      return {
        ...state,
        signUpPasswordlessLoading: true,
        signUpPasswordlessError: null,
      };
    }

    case SIGNUP_PASSWORDLESS_SUCCESS: {
      return {
        ...state,
        signUpPasswordlessLoading: false,
        signUpPasswordlessResponse: 'Success',
        signUpPasswordlessError: null,
      };
    }

    case SIGNUP_PASSWORDLESS_FAIL: {
      return {
        ...state,
        signUpPasswordlessLoading: false,
        signUpPasswordlessError: action.error && action.error.message,
      };
    }

    default:
      return state;
  }
}

export function isLoaded(globalState) {
  return globalState.auth && globalState.auth.loaded;
}

export function load() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) =>
      client.get(
        '/me?include=avatars,covers,tour,stats,preferences,sectors,skills,tools,diversity,undock,website'
      ),
    ignoreError: true,
  };
}

export function signUp(details) {
  const signupDetails = details;
  signupDetails.grant_type = 'register';

  return {
    types: [SIGNUP, SIGNUP_SUCCESS, SIGNUP_FAIL],
    promise: (client) =>
      client.post('/oauth/access_token', {
        data: jsonToFormData(signupDetails),
      }),
    schema: userSchema,
    ignoreError: true,
  };
}

export function googleOAuthRedirect(redirect = 'post') {
  return {
    types: [GOOGLEOAUTH, GOOGLEOAUTH_SUCCESS, GOOGLEOAUTH_FAIL],
    promise: (client) =>
      client.post('/oauth/googleAuth', {
        data: {
          redirect,
        },
      }),
  };
}

export function sendGoogleOAuthCode(
  code,
  redirect = 'post',
  userType = 'seller',
  aiCollector = false
) {
  return {
    types: [
      SENDGOOGLEOAUTHCODE,
      SENDGOOGLEOAUTHCODE_SUCCESS,
      SENDGOOGLEOAUTHCODE_FAIL,
    ],
    promise: (client) =>
      client.post('/oauth/google', {
        data: {
          code,
          grant_type: 'register',
          redirect,
          user_type: userType,
          ai_collector: aiCollector,
        },
      }),
  };
}

export function update(
  user,
  data,
  ignoreError = false,
  includes = [],
  updatingKey = '',
  lastUpdated = ''
) {
  let alwaysInclude = ['avatars', 'tour', 'stats', 'preferences', 'sectors'];
  alwaysInclude = alwaysInclude.concat(includes);

  return {
    types: [UPDATE, UPDATE_SUCCESS, UPDATE_FAIL],
    promise: (client) =>
      client.patch(`/users/${user.id}?include=${alwaysInclude.toString()}`, {
        data,
      }),
    ignoreError: ignoreError,
    schema: userSchema,
    updatingKey,
    lastUpdated,
    userId: user.id,
  };
}

export function updateTour(user, tour, seen = true) {
  const data = {
    tour: {
      [tour]: seen,
    },
  };

  return {
    types: [UPDATETOUR, UPDATETOUR_SUCCESS, UPDATETOUR_FAIL],
    promise: (client) =>
      client.patch(
        '/users/' + user.id + '?include=avatars,tour,stats,preferences,sectors',
        { data }
      ),
    field: tour,
  };
}

export function saveBraintreeNonce(user, braintreeNonce) {
  return {
    types: [
      SAVEBRAINTREENONCE,
      SAVEBRAINTREENONCE_SUCCESS,
      SAVEBRAINTREENONCE_FAIL,
    ],
    promise: (client) =>
      client.post(`/users/${user.id}/paypal`, { data: { braintreeNonce } }),
  };
}

export function addPushSubscription(endpoint, key, token) {
  return {
    types: [
      ADDPUSHSUBSCRIPTION,
      ADDPUSHSUBSCRIPTION_SUCCESS,
      ADDPUSHSUBSCRIPTION_FAIL,
    ],
    promise: (client) =>
      client.post('/notifications/push', {
        data: jsonToFormData({
          type: 'web',
          key,
          token,
          endpoint,
        }),
      }),
  };
}

export function updateLocal(userId, fields) {
  return {
    type: UPDATELOCAL,
    fields,
    result: {
      entities: {
        users: {
          [userId]: fields,
        },
      },
    },
  };
}

export function loadOwnCredits(userId, page = 1) {
  return {
    types: [LOADOWNCREDITS, LOADOWNCREDITS_SUCCESS, LOADOWNCREDITS_FAIL],
    promise: (client) =>
      client.get(
        `/users/${userId}/credits?active=1&parents_only=1&include=project,project.thumbnails&limit=12&page=${page}`
      ),
    schema: { credits: [creditSchema] },
  };
}

export function loadOwnBriefs(userId, page = 1) {
  return {
    types: [LOADOWNBRIEFS, LOADOWNBRIEFS_SUCCESS, LOADOWNBRIEFS_FAIL],
    promise: (client) =>
      client.get(
        '/users/' +
          userId +
          '/notices/collaboration?state=active,private,pending_approval&include_state=1&page=' +
          page
      ),
    schema: { collaborations: [briefSchema] },
  };
}

export function validateEmail(emailData) {
  const data = {
    email: emailData,
  };
  return {
    types: [VALIDATE_EMAIL, VALIDATE_EMAIL_SUCCESS, VALIDATE_EMAIL_FAIL],
    promise: (client) => client.post('/email_validation', { data }),
  };
}

export function setEmailInputToWarning() {
  return {
    type: EMAIL_VALIDATION_WARNING,
  };
}

export function setEmailInputToDanger() {
  return {
    type: EMAIL_VALIDATION_DANGER,
  };
}

export function resetEmailError() {
  return {
    type: EMAIL_ERROR_RESET,
  };
}

export function disconnectUndock() {
  return {
    type: UNDOCK_RESET,
  };
}

export function deleteAccount(userId) {
  return {
    types: [DELETEACCOUNT, DELETEACCOUNT_SUCCESS, DELETEACCOUNT_FAIL],
    promise: (client) => client.delete(`/users/${userId}`),
  };
}

export function resendEmailActivation(userID) {
  return {
    types: [
      RESEND_EMAIL_ACTIVATION,
      RESEND_EMAIL_ACTIVATION_SUCCESS,
      RESEND_EMAIL_ACTIVATION_FAIL,
    ],
    promise: (client) => client.post(`/users/${userID}/email/resendActivation`),
  };
}

/**
 * Submit auth code with email for verification
 * Can be used for both client-side submission and server-side data loading
 * @param {string} email - The email address to process
 * @returns {Object} Redux action with promise or error object if email is missing
 */
export function sendAuthCode(email) {
  if (!email) {
    return { error: 'No email provided' };
  }

  return {
    types: [
      CREATE_AUTH_CODE_REQUEST,
      CREATE_AUTH_CODE_SUCCESS,
      CREATE_AUTH_CODE_FAILURE,
    ],
    promise: (client) => client.post('/users/authCode', { data: { email } }),
    ignoreError: true, // Prevent global error banner from showing
  };
}

export function submitAuthCode(code, email) {
  const data = {
    code: code,
    email: email,
    grant_type: 'code',
  };

  return {
    types: [
      VERIFY_AUTH_CODE_REQUEST,
      VERIFY_AUTH_CODE_SUCCESS,
      VERIFY_AUTH_CODE_FAILURE,
    ],
    promise: (client) =>
      client.post('/oauth/access_token', { data: jsonToFormData(data) }),
    ignoreError: true,
  };
}

/**
 * Sign in with password
 * @param {string} username - The email or username to sign in with
 * @param {string} password - The password for authentication
 * @returns {Object} Redux action with promise
 */
export function signInWithPassword(username, password) {
  const data = {
    username,
    password,
    grant_type: 'password',
  };

  return {
    types: [
      PASSWORD_SIGNIN_REQUEST,
      PASSWORD_SIGNIN_SUCCESS,
      PASSWORD_SIGNIN_FAILURE,
    ],
    promise: (client) =>
      client.post('/oauth/access_token', { data: jsonToFormData(data) }),
    ignoreError: true,
  };
}

export function signUpPasswordless(signUpDetails) {
  const data = signUpDetails;
  return {
    types: [
      SIGNUP_PASSWORDLESS,
      SIGNUP_PASSWORDLESS_SUCCESS,
      SIGNUP_PASSWORDLESS_FAIL,
    ],
    promise: (client) => client.post('/users', { data: jsonToFormData(data) }),
    ignoreError: true,
  };
}
