import React from 'react';
import styles from '../Footer.module.scss';

const LocationJobs = () => {
  return (
    <ul className={styles.footerList}>
      <li className={styles.subTitle}>
        <a href="/jobs">
          <strong>Find Jobs by Location</strong>
        </a>
      </li>
      <li>
        <a href="/jobs/in/united-states">Find Jobs in the USA</a>
      </li>
      <li>
        <a href="/jobs/in/united-kingdom">Find Jobs in the UK</a>
      </li>
      <li>
        <a href="/jobs/in/canada">Find Jobs in the Canada</a>
      </li>
      <li>
        <a href="/jobs/in/australia">Find Jobs in the Australia</a>
      </li>
      <li>
        <a href="/jobs/in/germany">Find Jobs in the Germany</a>
      </li>
      <li>
        <a href="/jobs">Find Jobs Worldwide</a>
      </li>
    </ul>
  );
};

export default LocationJobs;
