import React from 'react';
import PropTypes from 'prop-types';

const AppWebDevelopmentSkills = ({ type }) => {
  if (type === 'freelancer') {
    return (
      <>
        <li>
          <a href="/jobs/app-developers">App Developer Jobs</a>
        </li>
        <li>
          <a href="/jobs/developers">Developer Jobs</a>
        </li>
        <li>
          <a href="/jobs/front-end-developers">Front-End Developer Jobs</a>
        </li>
        <li>
          <a href="/jobs/full-stack-developers">Full Stack Developer Jobs</a>
        </li>
        <li>
          <a href="/jobs/back-end-developers">Back-End Developer Jobs</a>
        </li>
        <li>
          <a href="/jobs/ai-engineers">AI Engineer Jobs</a>
        </li>
        <li>
          <a href="/jobs/web-developers">Web Developer Jobs</a>
        </li>
        <li>
          <a href="/jobs/game-developers">Game Developer Jobs</a>
        </li>
        <li>
          <a href="/jobs/blockchain-developers">Blockchain Developer Jobs</a>
        </li>
        <li>
          <a href="/jobs/web-designers">Web Designer Jobs</a>
        </li>
      </>
    );
  }

  return (
    <>
      <li>
        <a href="/find/app-developers">App Developers</a>
      </li>
      <li>
        <a href="/find/developers">Developers</a>
      </li>
      <li>
        <a href="/find/front-end-developers">Front-End Developers</a>
      </li>
      <li>
        <a href="/find/full-stack-developers">Full Stack Developers</a>
      </li>
      <li>
        <a href="/find/back-end-developers">Back-End Developers</a>
      </li>
      <li>
        <a href="/find/ai-engineers">AI Engineers</a>
      </li>
      <li>
        <a href="/find/web-developers">Web Developers</a>
      </li>
      <li>
        <a href="/find/game-developers">Game Developers</a>
      </li>
      <li>
        <a href="/find/blockchain-developers">Blockchain Developers</a>
      </li>
    </>
  );
};

AppWebDevelopmentSkills.propTypes = {
  type: PropTypes.string.isRequired,
};

export default AppWebDevelopmentSkills;
