import { List } from 'immutable';
import qs from 'query-string';
import config from '../config';
import {
  CREATIVE_DESIGN_JOBS,
  CREATIVE_ILLUSTRATION_JOBS,
  CREATIVE_MUSIC_JOBS,
  CREATIVE_PHOTO_JOBS,
  CREATIVE_VIDEO_JOBS,
} from 'constants/creativeJobs';
import {
  MIN_DESIGN_PROJECTS,
  MIN_MUSIC_PHOTO_PROJECTS,
  MIN_VIDEO_PROJECTS,
} from 'constants/minimumRequiredProjects';
import { ISO_COUNTRIES } from 'constants/constants';
import { singularRoleNames } from 'helpers/Constants';

export function jsonToFormData(
  obj,
  arrayNames = [],
  arrayValues = [],
  current = ''
) {
  let ikey;
  let value;

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      value = obj[key];
      ikey = current ? current + '[' + key + ']' : key;
      if (value instanceof File) {
        arrayNames.push(ikey);
        arrayValues.push(value);
      } else if (value instanceof Array || value instanceof Object) {
        if (value instanceof Array && value.length === 0) {
          arrayNames.push(ikey + '[]');
          arrayValues.push('');
        } else {
          jsonToFormData(value, arrayNames, arrayValues, ikey);
        }
      } else {
        arrayNames.push(ikey);
        arrayValues.push(value);
      }
    }
  }

  const string = {};
  for (let idx = 0; idx < arrayNames.length; idx++) {
    string[arrayNames[idx]] =
      arrayValues[idx] !== null && typeof arrayValues[idx] === 'boolean'
        ? arrayValues[idx]
          ? 1
          : 0
        : arrayValues[idx];
  }

  return string;
}

export function objectToArray(obj) {
  const arr = [];
  for (const index in obj) {
    if (obj.hasOwnProperty(index)) {
      arr.push(obj[index]);
    }
  }

  return arr;
}

export function hasNumber(myString) {
  return /\d/.test(myString);
}

export function extractNumberFromString(string) {
  return parseInt(string.match(/\d+/)[0], 10);
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function formatMoney(number, currencyCode = null, withPence = false) {
  const currencies = {
    usd: '$',
    gbp: '£',
    eur: '€',
  };
  let output;
  if (number >= 99999 || !withPence) {
    output = numberWithCommas((number / 100).toFixed(0));
  } else {
    output = numberWithCommas((number / 100).toFixed(2));
  }
  if (currencyCode) {
    if (currencies[currencyCode]) return currencies[currencyCode] + output;
  }
  return output;
}

export const convertCurrency = (amount, from, to) => {
  const ONE_USD_TO_GBP = 0.77;
  const ONE_GBP_TO_USD = 1.3;

  if (typeof amount !== 'number' || !isFinite(amount)) {
    return Number(0);
  }
  if (typeof from !== 'string' || typeof to !== 'string') {
    return Number(0);
  }
  if (to === from) {
    return Number(amount);
  }

  // GBP -> USD
  if (from === 'gbp' && to === 'usd') {
    return Number(amount * ONE_GBP_TO_USD);
  }

  // USD -> GBP
  if (from === 'usd' && to === 'gbp') {
    return Number(amount * ONE_USD_TO_GBP);
  }

  return Number(0);
};

export function beginsWithVowel(string) {
  return (
    ['a', 'e', 'i', 'o', 'u'].indexOf(string.toLowerCase().substring(0, 1)) !==
    -1
  );
}

export function isPlural(string) {
  return string.substring(string.length - 1) === 's';
}

export function toPlural(string) {
  if (!string) return string;
  // If it ends in 's' (also covers ending in 'ies', leave it alone
  if (string.charAt(string.length - 1) === 's') {
    return string;
  }

  // If it ends in 'y', pluralise by adding 'ies'
  if (string.charAt(string.length - 1) === 'y') {
    return `${string.substring(string.length - 1)}ies`;
  }

  // Otherwise just add 's'
  return `${string}s`;
}
export const pluralOrSingular = (count, singularWord) =>
  count > 1 ? toPlural(singularWord) : singularWord;

export function toSingular(string) {
  // Special cases that should remain unchanged
  if (singularRoleNames[string]) {
    return singularRoleNames[string];
  }

  // If string does not end in 's' or 'ies', leave it alone
  if (
    string.substring(string.length - 1) !== 's' &&
    string.substring(string.length - 3) !== 'ies'
  ) {
    return string;
  }

  // If it ends in 'ies', change to 'y'
  if (string.substring(string.length - 3) === 'ies') {
    return `${string.substring(0, string.length - 3)}y`;
  }

  // Otherwise just remove 's'
  return string.substring(0, string.length - 1);
}

export function aOrAn(word) {
  return beginsWithVowel(word) ? 'an' : 'a';
}

export function toTitleCase(string) {
  const str = string.toLowerCase().split(' ');

  return str
    .map((word) => `${word.charAt(0).toUpperCase()}${word.slice(1)}`)
    .join(' ');
}

export function convertCurrencyToSymbol(currencyString) {
  const currencies = {
    usd: '$',
    gbp: '£',
    eur: '€',
  };
  return currencies[currencyString] || currencyString;
}

export const getBrowseURL = ({ role, skill, country, city, location }) => {
  let query = {};

  if (role) {
    query = {
      role: toTitleCase(
        isPlural(role)
          ? toSingular(role).replace(/-/g, ' ')
          : role.replace(/-/g, ' ')
      ),
    };
  }
  if (skill) {
    query = { ...query, skill: toTitleCase(skill.replace(/-/g, ' ')) };
  }
  if (location) {
    query = { ...query, location };
  } else {
    const location = `${
      city ? `${toTitleCase(city.replace(/-/g, ' '))}, ` : ''
    }${country ? country.toUpperCase() : ''}`;

    if (location !== '') {
      query = {
        ...query,
        location,
      };
    }
  }

  Object.keys(query).forEach((key) => !query[key] && delete query[key]);

  const string = qs.stringifyUrl({
    url: '/browse',
    query,
  });

  return string;
};

export function loadExternalScript(
  script,
  additionalTags = [],
  callback = null
) {
  setTimeout(() => {
    // Make sure this script isn't already loaded
    if (document.querySelectorAll('script[src="' + script + '"]').length > 0) {
      console.warn('Stopping multiple loads of ' + script);
      if (callback) {
        callback();
      }
      return;
    }

    const body = document.getElementsByTagName('body')[0];
    const element = document.createElement('script');
    element.type = 'text/javascript';
    element.async = true;
    element.src = script;

    for (let idx = 0; idx < additionalTags.length; idx++) {
      element.setAttribute(additionalTags[idx].name, additionalTags[idx].value);
    }

    if (callback) {
      element.onreadystatechange = element.onload = () => {
        if (
          !callback.done &&
          (!element.readyState || /loaded|complete/.test(element.readyState))
        ) {
          callback.done = true;
          callback();
        }
      };
    }

    body.appendChild(element);
  }, 1);
}

export function flashHighlight(id, speed = 1000, zIndex = 1000) {
  const highlightSection = document.getElementById(id);

  if (!highlightSection) {
    return;
  }

  // create darken background
  const bg = document.createElement('div');
  bg.style.backgroundColor = '#000';
  bg.style.opacity = 0;
  bg.style.position = 'fixed';
  bg.style.top = 0;
  bg.style.bottom = 0;
  bg.style.left = 0;
  bg.style.right = 0;
  bg.style.transition = 'opacity ' + speed / 1000 + 's ease';
  document.getElementsByTagName('body')[0].appendChild(bg);

  // backup values
  const origZIndex = highlightSection.style.zIndex;
  const origPosition = highlightSection.style.position;

  if (highlightSection.nodeName === 'A') {
    highlightSection.focus();
  }
  // darken background
  setTimeout(() => (bg.style.opacity = 0.5), 1);
  // lighten background
  setTimeout(() => (bg.style.opacity = 0), speed);
  // remove background and unhighlight section
  setTimeout(() => {
    document.getElementsByTagName('body')[0].removeChild(bg);
    highlightSection.style.zIndex = origZIndex;
    highlightSection.style.position = origPosition;
  }, speed * 2);

  // hightlight section
  highlightSection.style.zIndex = zIndex;
  highlightSection.style.position = 'relative';

  setTimeout(() => {
    highlightSection.scrollIntoView();
    // remove 20px to scroll
    window.scrollBy(0, -20);
  }, 1);
}

export function getCoords(successCallback, errorCallback) {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) =>
        successCallback(position.coords.longitude, position.coords.latitude),
      (error) => {
        if (error.code === error.PERMISSION_DENIED) {
          errorCallback('You denied permission to your location');
        } else if (error.code === error.POSITION_UNAVAILABLE) {
          errorCallback('Unable to get your current location');
        } else if (error.code === error.TIMEOUT) {
          errorCallback('Getting your location timed out');
        } else if (error.code === error.UNKNOWN_ERROR) {
          errorCallback('Unknown error whilst getting your location (1)');
        } else {
          errorCallback('Unknown error whilst getting your location (2)');
        }
      }
    );
  } else {
    errorCallback('Your browser does not support getting your location');
  }
}

export function toSeoUrl(url) {
  if (!url) return url;
  // make the url lowercase
  let encodedUrl = url.toString().toLowerCase();

  // replace & with and
  encodedUrl = encodedUrl.split(/\&+/).join('-and-');

  //remove apostrophe
  encodedUrl = encodedUrl.split(/'+/).join('');

  // handle letters with accents (diacritics)
  encodedUrl = encodedUrl.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  // remove invalid characters
  encodedUrl = encodedUrl.split(/[^a-z0-9]/).join('-');

  // remove duplicates
  encodedUrl = encodedUrl.split(/-+/).join('-');

  // trim leading & trailing characters
  encodedUrl = encodedUrl.trim('-');

  return encodedUrl;
}

export function isValidURL(string) {
  const anchor = document.createElement('a');
  anchor.href = string;

  return anchor.host;
}

export function convertBoolToInt(obj) {
  for (const idx in obj) {
    if (obj[idx] !== null && typeof obj[idx] === 'object') {
      convertBoolToInt(obj[idx]);
    } else if (obj[idx] !== null && typeof obj[idx] === 'boolean') {
      obj[idx] = obj[idx] ? 1 : 0;
    }
  }

  return obj;
}

export function getThumbnailByWidth(thumbs, width) {
  // Get keys in reverse width order
  const keys = Object.keys(thumbs).sort((adx, bdx) =>
    thumbs[adx].width < thumbs[bdx].width ? 1 : -1
  );

  let retVal = thumbs[keys[0]];

  for (let idx = 1; idx < keys.length; idx++) {
    if (thumbs[keys[idx]].width < width) {
      break;
    }

    retVal = thumbs[keys[idx]];
  }

  return retVal.wide.main;
}

export function isUserVerified(user, overrideAvatar) {
  return (
    get(user, ['verified'], null) &&
    get(user, ['location', 'text'], '') !== '' &&
    (overrideAvatar ||
      (user.avatars &&
        user.avatars.images[30] &&
        user.avatars.images[30].square.main.indexOf('img/placeholders') === -1))
  );
}

export function hasAvatar(user) {
  if (!user) {
    return false;
  }

  if (
    typeof user.tempAvatar !== 'undefined' ||
    (typeof user.avatars !== 'undefined' &&
      typeof user.avatars.temp !== 'undefined')
  ) {
    return true;
  }

  if (typeof user.avatars === 'undefined') {
    return false;
  }

  const avatarSizes = Object.keys(user.avatars.images);

  return (
    user.avatars.images[avatarSizes[0]].square.main.indexOf(
      'img/placeholders'
    ) === -1
  );
}

export function calculateFeeOnCompletionWithoutVoucher(brief, pitch) {
  return pitch.deposit_amount > 0
    ? pitch.budget > 0
      ? pitch.amount - pitch.deposit_amount
      : brief.budget - pitch.deposit_amount
    : pitch.budget > 0
    ? pitch.amount
    : brief.budget;
}

export function calculateFeeOnCompletionWithVoucher(brief, pitch, voucher) {
  return voucher && voucher.amount > 0
    ? pitch.deposit_amount > 0
      ? pitch.deposit_amount - voucher.amount < 0
        ? pitch.amount > 0
          ? pitch.amount - voucher.amount < 0
            ? 0
            : pitch.amount - voucher.amount
          : brief.budget - voucher.amount < 0
          ? 0
          : brief.budget - voucher.amount
        : pitch.amount > 0
        ? pitch.amount - pitch.deposit_amount < 0
          ? 0
          : pitch.amount - pitch.deposit_amount
        : brief.budget - pitch.deposit_amount < 0
        ? 0
        : brief.budget - pitch.deposit_amount
      : pitch.amount > 0
      ? pitch.amount - voucher.amount < 0
        ? 0
        : pitch.amount - voucher.amount
      : brief.budget - voucher.amount < 0
      ? 0
      : brief.budget - voucher.amount
    : calculateFeeOnCompletionWithoutVoucher(brief, pitch);
}

export function calculatePayableNow(brief, pitch, voucher, paymentType) {
  return pitch.deposit_paid_at !== ''
    ? calculateFeeOnCompletionWithVoucher(brief, pitch, voucher)
    : pitch.deposit_amount > 0
    ? voucher && voucher.amount > 0
      ? pitch.deposit_amount - voucher.amount < 0
        ? 0
        : pitch.deposit_amount - voucher.amount
      : pitch.deposit_amount
    : paymentType === 'final'
    ? calculateFeeOnCompletionWithVoucher(brief, pitch, voucher)
    : 0;
}

export function currencySymbol(code, incCode = false) {
  if (code.toLowerCase() === 'gbp') {
    return '£' + (incCode ? ' GBP' : '');
  }

  if (code.toLowerCase() === 'eur') {
    return '€' + (incCode ? ' EUR' : '');
  }

  return '$' + (incCode ? ' USD' : '');
}

export function validateEmail(email) {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function isObject(item) {
  return item && typeof item === 'object' && !Array.isArray(item);
}

export function mergeDeep(target, source) {
  const output = Object.assign({}, target);

  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      if (isObject(source[key])) {
        if (!(key in target)) {
          Object.assign(output, { [key]: source[key] });
        } else {
          output[key] = mergeDeep(target[key], source[key]);
        }
      } else {
        Object.assign(output, { [key]: source[key] });
      }
    });
  }

  return output;
}

export function get(object, path, defaultResult = null) {
  const result = path.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), object);
  return result === null && defaultResult !== null ? defaultResult : result;
}

export function merger(a, b) {
  if (a && a.mergeWith && !List.isList(a) && !List.isList(b)) {
    return a.mergeWith(merger, b);
  }

  return b;
}

export const isMusicJob = (briefRole) =>
  CREATIVE_MUSIC_JOBS.includes(briefRole);

export const isDesignJob = (briefRole) =>
  CREATIVE_DESIGN_JOBS.includes(briefRole);

export const isVideoJob = (briefRole) =>
  CREATIVE_VIDEO_JOBS.includes(briefRole);

export const isIllustrationJob = (briefRole) =>
  CREATIVE_ILLUSTRATION_JOBS.includes(briefRole);

export const isPhotoJob = (briefRole) =>
  CREATIVE_PHOTO_JOBS.includes(briefRole);

export const isCreativeJob = (briefRole) =>
  isMusicJob(briefRole) ||
  isDesignJob(briefRole) ||
  isVideoJob(briefRole) ||
  isIllustrationJob(briefRole) ||
  isPhotoJob(briefRole);

export const moreExamplesNeededToPitch = (briefRole, portfolioRoles) => {
  const rolesReducer = (rolesCount, { name }) => {
    if (isIllustrationJob(name) || isDesignJob(name)) {
      rolesCount.illustrationDesign++;
    }
    if (isMusicJob(name)) {
      rolesCount.music++;
    }
    if (isPhotoJob(name)) {
      rolesCount.photo++;
    }
    if (isVideoJob(name)) {
      rolesCount.video++;
    }

    return rolesCount;
  };
  const zeroCount = {
    illustrationDesign: 0,
    music: 0,
    photo: 0,
    video: 0,
  };

  const countedRoles = Object.values(portfolioRoles).reduce(
    rolesReducer,
    zeroCount
  );

  const { illustrationDesign, music, photo, video } = countedRoles;
  // If illustration || design need 6 examples
  if (
    (isIllustrationJob(briefRole) || isDesignJob(briefRole)) &&
    illustrationDesign < MIN_DESIGN_PROJECTS
  ) {
    return [MIN_DESIGN_PROJECTS, MIN_DESIGN_PROJECTS - illustrationDesign];
  }

  // If music need 3 examples
  if (isMusicJob(briefRole) && music < MIN_MUSIC_PHOTO_PROJECTS) {
    return [MIN_MUSIC_PHOTO_PROJECTS, MIN_MUSIC_PHOTO_PROJECTS - music];
  }
  // If photo need 3 examples
  if (isPhotoJob(briefRole) && photo < MIN_MUSIC_PHOTO_PROJECTS) {
    return [MIN_MUSIC_PHOTO_PROJECTS, MIN_MUSIC_PHOTO_PROJECTS - photo];
  }

  // If video need 1 examples
  if (isVideoJob(briefRole) && video < MIN_VIDEO_PROJECTS) {
    return [MIN_VIDEO_PROJECTS, MIN_VIDEO_PROJECTS - video];
  }

  return false;
};

export function creativeMoreExamples(briefRole, portfolioExamples) {
  // If illustration need 6 examples
  if (
    (isIllustrationJob(briefRole) || isDesignJob(briefRole)) &&
    portfolioExamples < MIN_DESIGN_PROJECTS
  ) {
    return [MIN_DESIGN_PROJECTS, MIN_DESIGN_PROJECTS - portfolioExamples];
  }

  // If music need 3 examples
  if (
    (isMusicJob(briefRole) || isPhotoJob(briefRole)) &&
    portfolioExamples < MIN_MUSIC_PHOTO_PROJECTS
  ) {
    return [
      MIN_MUSIC_PHOTO_PROJECTS,
      MIN_MUSIC_PHOTO_PROJECTS - portfolioExamples,
    ];
  }

  // If video need 1 examples
  if (isVideoJob(briefRole) && portfolioExamples < MIN_VIDEO_PROJECTS) {
    return [MIN_VIDEO_PROJECTS, MIN_VIDEO_PROJECTS - portfolioExamples];
  }

  return false;
}

export function isUserCreative(user) {
  if (typeof user?.top_roles === 'undefined') {
    return false;
  }

  for (let idx = 0; idx < user.top_roles.length; idx++) {
    if (isCreativeJob(user.top_roles[idx])) {
      return true;
    }

    return false;
  }
}

export function calculateUserRating(numberOrRatedObject) {
  const DEFAULT_RATING = config.defaultUserRating;

  if (typeof numberOrRatedObject === 'number') {
    return numberOrRatedObject > 0 ? numberOrRatedObject : DEFAULT_RATING;
  }

  if (
    typeof numberOrRatedObject === 'object' &&
    numberOrRatedObject.hasOwnProperty('rating') &&
    numberOrRatedObject.rating.hasOwnProperty('avg')
  ) {
    return numberOrRatedObject.rating.avg >= 1
      ? numberOrRatedObject.rating.avg
      : DEFAULT_RATING;
  }

  return DEFAULT_RATING;
}

/**
 * Determines the text colour based on the background hex.
 *
 * @param {string} hexcolor
 * @param {string} [lightColor='#ffffff']
 * @param {string} [darkColor='#000000']
 */
export const getTextColour = (
  hexcolor = '#ffffff',
  lightColor = '#ffffff',
  darkColor = '#000000'
) => {
  const color =
    hexcolor.charAt(0) === '#' ? hexcolor.substring(1, 7) : hexcolor;
  const r = parseInt(color.substring(0, 2), 16); // hexToR
  const g = parseInt(color.substring(2, 4), 16); // hexToG
  const b = parseInt(color.substring(4, 6), 16); // hexToB
  return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? darkColor : lightColor;
};

/**
 * Checks whether user is in locations[]
 *
 * @param {string} locationText (eg 'London, United Kingdom')
 * @param {string[]} locations (eg ['united kingdom', 'united states'])
 */
export const checkUserLocation = (locationText = '', locations = []) => {
  const res = locations.find((location) =>
    locationText.toLowerCase().includes(location)
  );
  return res ? true : false;
};

// Capitalize first word in a string
export const capitalizeFirstWord = (s) => {
  return s && s[0].toUpperCase() + s.slice(1);
};

// Capitalize all words in a string
export const capitalizeWords = (item) => {
  const words = item.split(' ');

  return words
    .map((word) => {
      return word[0].toUpperCase() + word.substring(1);
    })
    .join(' ');
};

// Converts a segment of a url to text, so `united-kingdom` becomes `United Kingdom`
export const fromSeoUrl = (urlSegment) => {
  return capitalizeWords(urlSegment.replaceAll('-', ' '));
};

export const formatDate = (time, format = false) => {
  const date = new Date(time);
  switch (format) {
    case 'YYYY-MM-DD HH:MM:SS':
      return (
        date.getFullYear() +
        '-' +
        ('00' + (date.getMonth() + 1)).slice(-2) +
        '-' +
        ('00' + date.getDate()).slice(-2) +
        ' ' +
        ('00' + date.getHours()).slice(-2) +
        ':' +
        ('00' + date.getMinutes()).slice(-2) +
        ':' +
        ('00' + date.getSeconds()).slice(-2)
      );
    case 'day-month-DD':
      return (
        date.toLocaleString('default', {
          weekday: 'long',
        }) +
        ',  ' +
        date
          .toLocaleString('default', {
            month: 'long',
          })
          .substring(0, 3) +
        ' ' +
        date.getDate()
      );
    default:
      return date.toDateString();
  }
};

export const isHighSupplyLocation = (location) => {
  const highSupplylocations = ['India'];

  highSupplylocations.forEach((highSupplyLocation) => {
    {
      if (location.indexOf(highSupplyLocation) !== -1) return true;
    }
  });

  return false;
};

export const convertCountryCodeToCountryName = (countryCode) => {
  return ISO_COUNTRIES[countryCode] || null;
};

export const convertCountryNameToCountryCode = (countryName) => {
  const [countryCode] =
    Object.entries(ISO_COUNTRIES).find(
      ([key, value]) => value === countryName
    ) || [];
  return countryCode || null;
};

export const seoCountryNameToCountyCode = (countryName) => {
  const countryCode = convertCountryNameToCountryCode(fromSeoUrl(countryName));

  return countryCode == 'GB'
    ? 'uk'
    : countryCode
    ? countryCode.toLowerCase()
    : null;
};

export const createRandomPassword = () => {
  return Array(12)
    .fill(
      '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$'
    )
    .map(function (x) {
      return x[Math.floor(Math.random() * x.length)];
    })
    .join('');
};

export const removeMarkdownSyntax = (text) => {
  /* 
    Headers (### Header)
    /^(#+)/gm,
    Hyphenated Headers
    /^-{2,}\s*$/gm,
    Bold (**bold**)
    /\*\*(.*?)/g,
    Strikethrough (~~strikethrough~~)
    /~~(.*?)/g,
    Code (`code`)
    /`(.*?)/g,
    Images (![alt](url))
    /!\[|\]\(([^)]+)\)/g,
    Links ([link](url))
    /\[|\]\(([^)]+)\)/g,
    Horizontal Rule (--- or *** or ___)
    /^(---|\*\*\*|___)$/gm,
    Blockquotes (> quote)
    /^>\s/gm,
    Unordered lists (- item)
    /^([-*+]\s)/gm,
    Ordered lists (1. item)
    /^\d+\.\s/gm,
    Table (| header | header |)
    /\|(.*)\|/g,
    Code block (```language code```)
    /```(.*?)/g,
    Backslashes (\*\*\*)
    /\\/g,
  */

  const regex =
    /((#+)|(^-{2,}\s*$)|(\*\*(.*?))|(~~(.*?))|(`(.*?))|(!\[|\]\(([^)]+)\))|(\[|\]\(([^)]+)\))|(^(---|\*\*\*|___)$)|(^>\s)|(^([-*+]\s))|(^\d+\.\s)|(\|(.*)\|)|(```(.*?))|(\\))/gm;

  return text.replace(regex, '').trim();
};
