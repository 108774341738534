import React from 'react';
import PropTypes from 'prop-types';

const VideoAnimationSkills = ({ type }) => {
  if (type === 'freelancer') {
    return (
      <>
        <li>
          <a href="/jobs/2d-animators">2D Animation Jobs</a>
        </li>
        <li>
          <a href="/jobs/3d-animators">3D Animation Jobs</a>
        </li>
        <li>
          <a href="/jobs/animators">Animation Jobs</a>
        </li>
        <li>
          <a href="/jobs/actors">Acting Jobs</a>
        </li>
        <li>
          <a href="/jobs/illustrators Jobs">Illustration Jobs</a>
        </li>
        <li>
          <a href="/jobs/motion-graphic-designers">
            Motion Graphics Design Jobs
          </a>
        </li>
        <li>
          <a href="/jobs/videographers">Videographer Jobs</a>
        </li>
        <li>
          <a href="/jobs/video-editor">Video Editor Jobs</a>
        </li>
      </>
    );
  }

  return (
    <>
      <li>
        <a href="/find/2d-animators">2D Animators</a>
      </li>
      <li>
        <a href="/find/3d-animators">3D Animators</a>
      </li>
      <li>
        <a href="/find/animators">Animators</a>
      </li>
      <li>
        <a href="/find/animators/with/anime">Anime Animators</a>
      </li>
      <li>
        <a href="/find/videographers">Videographers</a>
      </li>
      <li>
        <a href="/find/video-editors">Video Editors</a>
      </li>
      <li>
        <a href="/find/illustrators">Illustrators</a>
      </li>
      <li>
        <a href="/find/motion-graphic-designers">Motion Graphic Designers</a>
      </li>
      <li>
        <a href="/find/content-producers/with/explainer-video">
          Explainer Videos
        </a>
      </li>
      <li>
        <a href="/find/actors">Actors</a>
      </li>
    </>
  );
};

VideoAnimationSkills.propTypes = {
  type: PropTypes.string.isRequired,
};

export default VideoAnimationSkills;
