import React from 'react';
import PropTypes from 'prop-types';

const BusinessStartupSkills = ({ type }) => {
  if (type === 'freelancer') {
    return (
      <>
        <li>
          <a href="/jobs/ai-engineers">AI Engineer Jobs</a>
        </li>
        <li>
          <a href="/jobs/app-developers">App Developer Jobs</a>
        </li>
        <li>
          <a href="/jobs/digital-marketers">Digital Marketing Jobs</a>
        </li>
        <li>
          <a href="/jobs/pitch-deck-designers">Pitch Deck Designer Jobs</a>
        </li>
        <li>
          <a href="/jobs/brand-designers">Brand Designer Jobs</a>
        </li>
        <li>
          <a href="/jobs/pr-freelancers">PR Freelancer Jobs </a>
        </li>
        <li>
          <a href="/jobs/2d-animators">2D Animation Jobs </a>
        </li>
        <li>
          <a href="/jobs/3d-animators">3D Animation Jobs </a>
        </li>
        <li>
          <a href="/jobs/web-developesr">Web Developer Jobs</a>
        </li>
        <li>
          <a href="/jobs/product-designers">Product Designer Jobs</a>
        </li>
        <li>
          <a href="/jobs/social-media-managers">Social Media Managers</a>
        </li>
      </>
    );
  }

  return (
    <>
      <li>
        <a href="/find/content-producers/with/explainer-video">
          Explainer Video Experts
        </a>
      </li>
      <li>
        <a href="/find/pitch-deck-designers">Pitch Deck Designers</a>
      </li>
      <li>
        <a href="/find/content-creators">Content Creators</a>
      </li>
      <li>
        <a href="/find/digital-marketers">Digital Marketers</a>
      </li>
      <li>
        <a href="/find/social-media-managers">Social Media Managers</a>
      </li>
      <li>
        <a href="/find/pr-freelancers">PR Freelancers</a>
      </li>
      <li>
        <a href="/find/ppc-managers">PPC Managers</a>
      </li>
      <li>
        <a href="/find/podcast-producers">Podcast Producers</a>
      </li>
      <li>
        <a href="/find/app-developers">App Developers</a>
      </li>
      <li>
        <a href="/find/web-developers">Web Developers</a>
      </li>
      <li>
        <a href="/find/product-designers">Product Designers</a>
      </li>
    </>
  );
};

BusinessStartupSkills.propTypes = {
  type: PropTypes.string.isRequired,
};

export default BusinessStartupSkills;
