import React from 'react';
import PropTypes from 'prop-types';

const DefaultSkills = ({ type }) => {
  if (type === 'freelancer') {
    return (
      <>
        <li>
          <a href="/jobs/animators">Animation Jobs</a>
        </li>
        <li>
          <a href="/jobs/videographers">Videographer Jobs</a>
        </li>
        <li>
          <a href="/jobs/graphic-designers">Graphic Design Jobs</a>
        </li>
        <li>
          <a href="/jobs/pitch-deck-designers">Pitch Deck Designer Jobs</a>
        </li>
        <li>
          <a href="/jobs/social-media-marketers">Social Media Marketing Jobs</a>
        </li>
        <li>
          <a href="/jobs/digital-marketers">Digital Marketing Jobs</a>
        </li>
        <li>
          <a href="/jobs/music-composers">Music Composer Jobs</a>
        </li>
        <li>
          <a href="/jobs/music-producers">Music Producer Jobs</a>
        </li>
        <li>
          <a href="/jobs/web-designers">Web Designer Jobs</a>
        </li>
        <li>
          <a href="/jobs/web-developers">Web Developer Jobs</a>
        </li>
      </>
    );
  }

  return (
    <>
      <li>
        <a href="/find/animators">Animators</a>
      </li>
      <li>
        <a href="/find/videographers">Videographers</a>
      </li>
      <li>
        <a href="/find/graphic-designers">Graphic Designers</a>
      </li>
      <li>
        <a href="/find/pitch-deck-designers">Pitch Deck Designers</a>
      </li>
      <li>
        <a href="/find/social-media-marketers">Social Media Marketers</a>
      </li>
      <li>
        <a href="/find/digital-marketers">Digital Marketers</a>
      </li>
      <li>
        <a href="/find/music-composers">Music Composers</a>
      </li>
      <li>
        <a href="/find/music-producers">Music Producers</a>
      </li>
      <li>
        <a href="/find/web-designer">Web Designer</a>
      </li>
      <li>
        <a href="/find/web-developers">Web Developers</a>
      </li>
    </>
  );
};

DefaultSkills.propTypes = {
  type: PropTypes.string.isRequired,
};

export default DefaultSkills;
