import React from 'react';
import styles from '../Footer.module.scss';
import SvgIcon from 'components/SvgIcon/SvgIcon';

const Socials = () => {
  return (
    <ul className={styles.footerList + ' ' + styles.copyright}>
      <li>
        &copy; Clowdy Ltd. t/a Twine.{' '}
        <a href="/terms" title="Terms Of Service">
          Terms
        </a>{' '}
        -{' '}
        <a href="/privacy" title="Privacy Policy">
          Privacy
        </a>
      </li>

      <li>
        <ul className={`${styles.socialList}`}>
          <li>
            <a
              rel="nofollow noopener noreferrer"
              href="https://www.facebook.com/jointwine"
              target="_blank"
              title="Twine on Facebook"
            >
              <SvgIcon size="20" icon="facebook" />
            </a>
          </li>
          <li>
            <a
              rel="nofollow noopener noreferrer"
              href="https://x.com/jointwine"
              target="_blank"
              title="Twine on X"
            >
              <SvgIcon size="20" icon="x" />
            </a>
          </li>
          <li>
            <a
              rel="nofollow noopener noreferrer"
              href="https://www.instagram.com/jointwine"
              target="_blank"
              title="Twine on Instagram"
            >
              <SvgIcon size="20" icon="instagram" />
            </a>
          </li>
        </ul>
      </li>
    </ul>
  );
};

export default Socials;
